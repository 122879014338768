<template>
    <logged-in>
        <template v-slot:content>
            <div class="h-full mx-auto rounded md:mt-8 shadow  max-w-md">
                <div class="bg-white">
                    <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl text-center">
                        <div class='flex-col text-white pb-10'>
                            <div class='grid grid-cols-5'>
                                <div class='col-span-4'>
                                    <div class="text-4xl text-left font-semibold text-gray-500 pl-4"> 
                                        {{Language.PATIENT_ID}}
                                    </div>
                                    <div class="pl-2 text-left text-sm font-normal pb-4 text-gray-500">
                                        <Address />
                                    </div>
                                </div>
                                <div class='col-span-1 '>
                                    <div  class='float-right pt-2'>
                                        <button class="text-2xl rounded-xl text-warm-gray-600 w-12" @click="showId = !showId">
                                            <i  v-if="!showId" class="fas fa-qrcode"></i>
                                            <i v-else class="fas fa-eye-slash"></i>
                                        </button>
                                    </div> 
                                </div>
                            </div>
                            <div class='items-justify-center' > 
                                <div class='mx-auto' :style="inlineBgImage(blueCircleSrc)">
                                    <div class="py-16 text-7xl text-green-400 items-justify-center" >
                                        <PatientQR class="" v-if="showId ||  tokens.length < 2 || tokens[0].mfg === VXVaccineConstants.VACCINE_MANUFACTURERS['Janssen']"/>
                                        <!-- <CheckCircleIcon v-if="isComplete && !showId" style='min-height:225px' class='text-green-400 h-32 w-32 mx-auto' /> -->
                                        <div class=''>
                                        <i v-if="isComplete && !showId" class="fas fa-check-double my-auto pt-14" style='min-height:180px'></i>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button v-if="showCheck" @click="checkRecords(store)" class="p-2 m-2 text-green-400 rounded-xl shadow"> 
                                    <div v-if="!searching" class='flex'>
                                        <CheckIcon class='h-6 w-6'/> 
                                        {{Language.CHECK_RECORDS}}
                                    </div>
                                    <div v-else class='flex'>
                                        <i class='fa fa-spinner animate-spin'></i>
                                        {{Language.CHECK_RECORDS}}
                                    </div>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="mt-3 pt-4 grid gap-2 flex">
                            <div class=' flex-grow p-2 m-2'> 
                                <card-simple headerText="Covid" buttonText="DoSomething" :showRightText="true" rightText="Dose 1" rightTextColor="text-indigo-600" extraClasses="ring-1 ring-indigo-600  shadow"> 
                                    <template v-slot:content>
                                        <div v-if="tokens.length === 0">
                                            No Records Found
                                        </div>
                                        <div v-else>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.MANUFACTURER}}: </label>
                                                <div class="text-xl flex-grow"> {{tokens[0].mfg}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.DISTRIBUTED_DATE}}: </label>
                                                <div class="text-lg flex-grow"> {{tokens[0].distributed}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.IS_VERIFIED}}: </label>
                                                <div class="text-lg flex-grow"> {{checkVerify(tokens[0])}} </div>
                                            </div>
                                        </div>
                                    </template>
                                </card-simple>
                            </div>
                            <div v-if="tokens.length !== 0 && tokens[0].mfg !== VXVaccineConstants.VACCINE_MANUFACTURERS['Janssen']" class='p-2 m-2'> 
                                <card-simple headerText="Covid" buttonText="DoSomething" :showRightText="true" rightText="Dose 2" rightTextColor="text-pink-700" extraClasses="ring-1 ring-pink-500 shadow"> 
                                    <template v-slot:content>
                                        <div v-if="tokens.length === 0">
                                            No Records Found
                                        </div>
                                        <div v-else>
                                        <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.MANUFACTURER}}:</label>
                                                <div class="text-xl flex-grow">{{tokens[1].mfg}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.DISTRIBUTED_DATE}}: </label>
                                                <div class="text-lg flex-grow"> {{tokens[1].distributed}} </div>
                                            </div>
                                            <div class="flex items-center justify-center">
                                                <label class="text-md"> {{Language.IS_VERIFIED}}: </label>
                                                <div class="text-lg flex-grow"> {{checkVerify(tokens[1])}} </div>
                                            </div>
                                        </div>
                                    </template>
                                </card-simple>
                            </div>
                        </div> -->
                    </div>
                    <div v-if="tokens.length > 0" class=''>
                        <div v-for="token in tokens" :key='token.location' class="bg-white col-span-1 shadow-2xl rounded-xl m-1 ">
                            <dose-details :token="token"></dose-details>
                        </div>
                    </div>
                     <div  class="m-10 mb-0">
                        <img ref="picture" />
                    </div>
                    <div class="mx-auto pt-10">
                        <status-bar :steps="steps"></status-bar>
                    </div>
                </div>
            </div>
        </template>
    </logged-in>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { CheckIcon} from "@heroicons/vue/outline"
import {useStore} from "vuex";
import LoggedIn from "./layouts/LoggedIn.vue"
//import CardSimple from "./../../../components/tailwind/cards/CardSimple.vue"
import Run from "run-sdk";
import Utilities from "./../../../helpers/Utilities.js"
import PatientQR from "./../../../components/apps/patient/status/PatientQR.vue"
import Address from "./../../../components/apps/patient/profile/Address.vue"
import Language from './../../../constants/language/english.js'
import ConstantsManager from "./../../../managers/ConstantsManager.js";
//import QRWrapper from '../../../components/apps/common/QRWrapper.vue';
import StatusBar from '../../../components/apps/patient/status/StatusBar.vue';
import DoseDetails from '../../../components/apps/patient/status/DoseDetails.vue';


//import VXPassSVG from "./../../../components/apps/common/svgs/VXPass.vue"
const blueCircleSrc = '/images/circles/BluePurpleDoubleCircle400.png';

export default {
    async setup () {
       let store = useStore();
       let searching = ref(false);
        
       let tokens = ref([]);
       let _network = store.state.network;
       if(!_network){alert('no network selected')}
        const initCheck = async (_store) =>{ 
            //const blockchain = new Run.plugins.WhatsOnChain({ network: 'test' })
            console.log("PatientSeed:", _store.state.patientSeed)
            console.log("PatientLiteSeed:", _store.state.patientLiteSeed)
            console.log("Patient Private key", _store.state.patientPrivKey)
            let run = new Run({network: store.state.network, trust:"*", owner: _store.state.patientPrivKey, networkTimeout: 20000})
            await run.activate();
            try{
                console.log("syncing");
                await run.inventory.sync()
            }catch(err){
                console.log(err)
            }
            console.log("synced");
            let tokens = Utilities.findRecords(run);
            if(tokens.length > 0 ){
                _store.commit("resetPatientTokens")
                _store.commit("setPatientTokens", tokens)
            }
            return tokens;

        }
        
        const _showId = (tokens.value && tokens.value.length < 2) 
        const checkVerify = Utilities.verifyRecordSig;
        tokens = await initCheck(store);
        
        const state = reactive({
            tokens: tokens,
            showId: _showId,
            searching: false,
        })
        return {
            blueCircleSrc,
            Language,
            searching,
            initCheck,
            checkVerify,
            ConstantsManager,
            ...toRefs(state),
            store,
        }
    },
    computed:{
        isComplete(){
            console.log(this.tokens.length);
            let response = false;
            if(this.tokens.length >= 2 ){response = true}
            if(this.tokens.length === 1){
                if(this.tokens[0].mfg === ConstantsManager.vaccineManufacturers().Janssen){
                    response = true;
                }
            }
            console.log(response)
            return response;
        },
        steps(){
            let dSteps =  [];
            if(this.tokens.length === 0){
                dSteps =  [
                    { name: 'Step 1', stepType: '#', status: 'current' },
                    { name: 'Step 2', stepType: '#', status: 'current' },
                    { name: 'Step 3', stepType: '#', status: 'current' },
                    { name: 'Step 4', stepType: 'final', status: 'final' },
                ]
            }else if(this.tokens.length === 1){
                dSteps =  [
                    { name: 'Step 1', stepType: '#', status: 'complete' },
                    { name: 'Step 2', stepType: '#', status: 'current' },
                    { name: 'Step 3', stepType: '#', status: 'current' },
                    { name: 'Step 4', stepType: 'final', status: 'final' },
                ]
            }else if(this.tokens.length >= 2){
                dSteps =  [
                    { name: 'Step 1', stepType: '#', status: 'complete' },
                    { name: 'Step 2', stepType: '#', status: 'complete' },
                    { name: 'Step 3', stepType: '#', status: 'complete' },
                    { name: 'Step 4', stepType: 'final', status: 'final' },
                ]
            }

          
            return dSteps;
        },
        showCheck(){
            if (this.tokens && this.tokens.length < 2) return true;
            return false;
        },
      
    },
    async mounted() {
        console.log("mounted")
         const isLoggedIn = () => {
            if(this.$store.state.patientPrivKey === "" || this.$store.state.patientSeed === ""){
                return false;
            }
            return true;
        }
        if(!isLoggedIn())
            this.$router.push('/apps/patient/login');
        if(this.tokens.length === 0){
            await this.initCheck(this.$store);
        }
        let file =  localStorage.card_file2;
        console.log(file)
        if(file)
            this.$refs.picture.src = file;
        
    },
    methods:{
        async checkRecords(){
            this.searching = true;
            this.tokens = await this.initCheck(this.store);
            this.searching = false;

        },
        inlineBgImage(src) {
            let bgImage = require('@/assets' + src)

            return {
                backgroundImage: `url("${bgImage}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "center", 
                backgroundSize: "300px 300px",
            }
        },
    },
    components:{
        LoggedIn,
        //CardSimple,
        PatientQR,
        Address,
        CheckIcon,
        //QRCodeIcon,
        StatusBar,
        DoseDetails,
        //VXPassSVG
    }
}
</script>

<style lang="scss" scoped>

</style>