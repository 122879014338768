<template>
    <div class='p-1 m-1 text-left'>
        <div v-for="cred in creds" :key="cred.location" class='grid grid-cols-6 pb-2'>
            <div class='col-span-1'>
                <img class="h-16 w-16 rounded-full" :class="selectedCredClasses(cred.location)" :src="cred.factory.logoUrl" alt="" />    
            </div>
            <div class='col-span-3'>
                <div class="pt-1.5 justify-center p-1 m-1">
                    <h1 class="text-md text-left font-bold text-gray-900">{{cred.factory.organization}}</h1>
                    <p class="text-sm font-medium text-gray-500">Issued on:  <time datetime="2020-08-25">{{printDate(cred.issuedDate)}}</time></p>
                </div>
            </div>
            <div class='col-span-1'>
                <div class="flex justify-center pt-3">
                    <a  target="_blank" rel="noreferrer noopen" :href="`https://run.network/explorer/?query=${cred.location}&network=${network}`" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                        <i class='fa fa-eye pr-2'></i> view
                    </a>
                </div>
            </div>
            <div class='col-span-1'>
                <div class="flex items-right pt-3">
                    <div class="w-full"></div>
                    <div>
                        <button @click="toggleCredential(cred)" 
                        type="button"  
                        :class="selectedCredButtonClasses(cred.location)"
                        class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                            .
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs } from 'vue'
import {useStore} from "vuex";
import Run from "run-sdk";
import ConstantsManager from "./../../../../managers/ConstantsManager.js";

export default {
    components: {},
    setup () {
        let store = useStore();
        let creds = ref(store.state.credentials);
        console.log("Credentials at CompactList setup():", creds.value);
        const state = reactive({
            network: store.state.network,
        })
        onMounted(async ()=>{
            let _network = store.state.network; 
            let _run = new Run({network: _network, trust: '*', purse: ConstantsManager.paymentPrivateKey(_network), owner: store.state.providerPrivKey })
            await store.dispatch("fetchProviderCredentials", _run);
        })
        return {
            creds,
            ...toRefs(state),
        }
    },
    methods:{
        printDate(dateString){
            let d = new Date(dateString);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            console.log(`${da}-${mo}-${ye}`);
            return `${da}-${mo}-${ye}`;
        },
        getLatestDate(){
            let _d ;
            if(this.creds[0] !== undefined){
                _d= new Date(this.creds[0].issuedDate)
                this.creds.forEach(element => {
                    let _da = new Date(element.issuedDate)
                    if(_da > _d){_d = _da}
                });
            }
            if(_d)return this.printDate(_d.toString());
            else return "";
            
        },
        useCredential(cred){
            this.$store.commit("setSelectedCredential", cred);
            this.$emit("credential-selected");
        },
        isSelectedCredential(location){
            let isCred = false; 
            if(this.$store.state.selectedCredential && this.$store.state.selectedCredential.location === location){isCred = true}
            return isCred;
        },
        selectedCredClasses(location){
            if(this.isSelectedCredential(location)){return "ring-2 ring-indigo-600";}
            return "ring-2 ring-red-600";
        },
        selectedCredButtonClasses(location){
            if(this.isSelectedCredential(location)){return "text-indigo-600 bg-indigo-600";}
            return "text-gray-600  bg-gray-600 rounded-full";
        },
        clearCredential(){
            this.$store.commit("setSelectedCredential", null);
        },
        toggleCredential(cred){
            let isSelected = this.isSelectedCredential(cred.location);
            if (!isSelected){this.useCredential(cred)}
            else {this.clearCredential()}
        },
    },
    computed:{
    },
    props:{
        showHeader: Boolean,
    }
}
</script>


<style lang="scss" scoped>

</style>