<template>
    <div>
        <nav aria-label="Progress">
            <ol class="overflow-hidden">
                <li v-for="(step, stepIdx) in steps2" :key="step.name" :class="[stepIdx !== steps.length - 1 ? '' : '', 'relative']">
                    <div :class="[step.status === 'current' ? 'rounded-xl' : '' , 'grid grid-cols-3 pr-2']">
                        <template v-if="step.type === 'dose'">
                            <dose :step="step" :stepIdx="stepIdx" :length="steps.length - 1"></dose>
                        </template>
                         <template v-if="step.type === 'wait'">
                             <wait :step="step" :stepIdx="stepIdx" :length="steps.length -1"></wait>
                         </template>
                         <template v-if="step.type === 'status'">
                            <status :step="step" :stepIdx="stepIdx" :length="steps.length -1"> </status>
                         </template>
                          <template v-if="step.type === ''">
                            <div class='p-3 m-3'></div>
                         </template>
                    </div>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
import { inject, reactive, toRefs } from 'vue'
import Dose from './Dose.vue';
import Wait from './Wait.vue';
import Status from './Status.vue';
import ConstantsManager from "./../../../../managers/ConstantsManager.js";
import Utilities from '../../../../helpers/Utilities';
const blueCircleSrc = '/images/circles/BluePurpleDoubleCircle400.png';
const greenCircleSrc = '/images/circles/GreenGreenDoubleCircle400.png';
//import VerticalTimeline from './../../../tailwind/steps/VerticalTimeline.vue'

const steps = [
  { type:"dose", name: '1st Dose', description: 'First Dose', manufacturer: "Pfizer", lotId: "12345",  status: 'complete'},
  {
    type:'wait',
    name: 'Waiting Period',
    description: 'Reccomended Days Between Doses',
    days: 18,
    status: 'current',
  },
  { type: "dose", name: '2nd Dose', description: 'Second Dose',  manufacturer: "Pfizer", lotId: "12345", status: 'upcoming' },
  { type: "status", name: 'Status', description: 'Your regimen has been completed', status: 'incomplete' },
]

export default {
  components: {
    Dose,
    Wait,
    Status
      //VerticalTimeline  
    },
    setup () {
        const Language = inject('Language');
        const state = reactive({
            count: 0,
        })
    
        return {
            steps,
            Language,
            blueCircleSrc,
            greenCircleSrc,
            ...toRefs(state),
        }
    },
    methods:{
        inlineBgImage(src) {
            let bgImage = require('@/assets' + src)

            return {
                backgroundImage: `url("${bgImage}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "center", 
                backgroundSize: "75px 75px",
            }
        },
        daysRemaining(date){
            let _days = Utilities.daysUntil(date)
            console.log("days Remaining:", _days)
            return _days;
        }, 
        nextDoseDate(_mfg, _waitPeriod){
            console.log("Distributed @ nextDoseDate():", this.$store.state.patientTokens[0].distributed);
            let _sd = new Date(this.$store.state.patientTokens[0].distributed);
            console.log("Start Date: ", _sd);
            _sd.setDate(_sd.getDate() + _waitPeriod);
            return _sd;
        },
        nextDoseExpires(_startDate, _daysToElapse){
            let _sd = new Date(_startDate.toString());
            _sd.setDate(_sd.getDate() + _daysToElapse);
            return _sd;
        },
    },
    computed:{
        steps2(){
            let _steps = [];
            console.log("Pateint Tokens Length:", this.$store.state.patientTokens.length)
            let waitPeriod = ConstantsManager.doseInterval(this.$store.state.selectedManufacturer.replace(' ', ''));
            if(this.$store.state.patientTokens.length === 0){                
                _steps.push({ type:"dose", name: `${this.Language.FIRST_NUMBER} ${this.Language.DOSE}`, description: 'First Dose', manufacturer: this.$store.state.selectedManufacturer, lotID: this.$store.state.selectedLotID,  status: 'current'})
                //TODO: Make this work with a 1 step protocol. 
                if(this.$store.state.selectedManufacturer === ConstantsManager.appConstants().VACCINE_MANUFACTURERS.Janssen){
                    _steps.push({ type: "wait", name: '', description: this.Language.NO_WAITING_PERIOD, days: waitPeriod ? waitPeriod : 0, status: 'pending' });
                } else{
                     _steps.push({ type:'wait', name: this.Language.WAITING_PERIOD, description: this.Language.RECOMENDED_DAYS_BETWEEN_DOSES, days: waitPeriod ? waitPeriod : 0, status: 'pending'})
                     _steps.push({ type: "dose", name: `${this.Language.SECOND_NUMBER} ${this.Language.DOSE}`, description: 'Second Dose',  manufacturer: "", lotID: "", status: 'pending'})
                }
                _steps.push({ type: "status", name: 'Status', description: 'Your regimen is', status: 'pending' });
            }else if(this.$store.state.patientTokens.length > 0 ){
                _steps.push({ type:"dose", name: '1st Dose', description: 'First Dose', manufacturer: this.$store.state.patientTokens[0].mfg, lotID: this.$store.state.patientTokens[0].lot,  status: 'complete'})
                if(this.$store.state.patientTokens.length === 1){
                    if(this.doseTotal === 1){
                        _steps.push({ type:'wait', name: 'Waiting Period', description: 'Reccomended Days Between Doses', days: 0, status: 'complete'})
                        _steps.push({ type: "status", name: 'Status', description: 'Your regimen has been completed', status: 'complete' },)       
                    }else{
                        let _waitStatus = "current";
                        let _secondDoseStatus = "pending";
                        let _waitName = "Waiting Period";
                        let _description =  'Remaing Before Next Dose';
                        let _mfg = this.$store.state.patientTokens[0].mfg;
                        waitPeriod = Number.parseInt(ConstantsManager.doseInterval(_mfg.replace(' ', '')));
                        console.log("127: MFG:", _mfg, "waitPeriod", waitPeriod);
                        let _nextDose = this.nextDoseDate(_mfg, waitPeriod);
                        console.log("Next Dose:", _nextDose)
                        let _daysUntil = this.daysRemaining(_nextDose);
                        let _daysAvailable = Number.parseInt(ConstantsManager.appConstants().VACCINE_INTERVAL_DAYS_AVAILABLE[_mfg]);
                        console.log("Days Available: ", _daysAvailable, "from :", _nextDose);
                        let _expiresOn = this.nextDoseExpires(_nextDose, _daysAvailable);
                        console.log("Window Expires on: ", _expiresOn);
                        if(_daysUntil < 0 ){
                            _waitName = "Ready";
                            _daysUntil = this.daysRemaining(_expiresOn)
                            _description = "remain until your windows has expired. "
                            _waitStatus = "complete"
                            _secondDoseStatus = "current"
                            console.log({_waitName, _daysUntil, _description, _waitStatus, _secondDoseStatus})
                        }
                        _steps.push({ type:'wait', name: _waitName, description:_description, days: _daysUntil,  status: _waitStatus})       
                        _steps.push({ type: "dose", name: '2nd Dose', description: 'Second Dose',  manufacturer: "", lotId: "", status: _secondDoseStatus})
                        _steps.push({ type: "status", name: 'Status', description: '', status: 'pending' },)    
                    }
                }else if(this.$store.state.patientTokens.length === 2){
                    _steps.push({ type:'wait', name: 'Waiting Period', description: 'Reccomended Days Between Doses', days: 0, status: 'complete'})       
                    _steps.push({ type: "dose", name: '2nd Dose', description: 'Second Dose',  manufacturer: this.$store.state.patientTokens[1].mfg, lotID: this.$store.state.patientTokens[1].lot, status: 'complete'})
                    _steps.push({ type: "status", name: 'Status', description: 'Your regimen has been completed', status: 'complete' },)
                }
            }
            return _steps;
        },
        doseTotal(){
            let response = ConstantsManager.appConstants().VACCINE_DOSE_TOTAL[this.$store.state.selectedManufacturer];
            console.log("Dose Total: ", response)
            return response;
        },
        
    }
}
</script>

<style lang="scss" scoped>

</style>