<template>
    <login-split-screen v-if="!isLoggedIn">
        <template v-slot:leftPanel>
            <div v-if="showLogin" class="mx-auto w-full max-w-sm lg:w-96">
                <div class=''>
                    <div class="flex-col items-center justify-center">
                        <img class="h-24 w-auto" src="./../../../assets/images/vx-provider-logotype.png" alt="Workflow" />
                        <h2 class="mt-2 text-3xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-600 to-indigo-700">
                            {{Language.SIGN_IN_TEXT}}
                        </h2>
                    </div>
                    
                </div>
                <div class="mt-6">
                    <LoginForm :loginType="loginType" :showRegister="showRegister" />
                     <div class='p-8'> 
                        <button v-if="store.state.network !== ConstantsManager.appConstants().TEST_NETWORK"  @click="setNetwork(ConstantsManager.appConstants().TEST_NETWORK)"> Set Test Network </button>
                        <button v-if="store.state.network !== ConstantsManager.appConstants().MAIN_NETWORK"  @click="setNetwork(ConstantsManager.appConstants().MAIN_NETWORK)"> Set MAIN Network </button>
                    </div> 
                </div>
            </div>
            <div v-if="!showLogin" class="mx-auto w-full max-w-sm lg:w-96">
                <div class="">
                    <img class="h-20 w-auto" src="./../../../assets/images/vx-provider-logotype.png" alt="Workflow" />
                    <h2 class="mt-2 text-3xl font-medium  text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-indigo-600 to-indigo-700">
                        {{Language.CARE_PROVIDER_REGISTRATION_TITLE}}
                    </h2>
                </div>
                <div class="h-auto overflow-y-scroll">
                    <RegistrationForm :showLoginClick="hideRegister" />
                </div>
                <div class='flex pt-4'> 
                 
                </div>
            </div>
        </template>
        <template v-slot:rightPanel>
            <div class="bg-indigo-600">
            <img :class="imageClasses" :src="backgroundImage" alt="" />
            </div>
        </template>
    </login-split-screen>
    <div >
          <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="./../../../assets/images/vx-provider-logotype.png" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Are You Sure?
            </h2>
             <div v-if="unsentTransactions.length > 0">
                <button @click="navigateTo('/apps/provider/history')" class='shadow-xl rounded-xl ring-2 ring-red-300' >
                <div class='text-3xl text-red-500 font-semibold'> There are unsent records in memory </div>
                <div class='text-lg text-red-500 font-semibold'> Please send before logging out. </div>
                </button>
            </div>
            <p class="mt-2 text-center text-sm text-gray-600">
                <a href="/apps/provider/keys" class="font-semibold text-red-600 hover:text-indigo-500">
                make sure to save your VXPassphrase
                </a>
            </p>
            </div>
           
            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div class="space-y-6" action="#" >
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <input v-model="checked" id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                                    I have secured my VXPassphrase
                                </label>
                            </div>
                            <div class="text-sm">
                                <a href="/apps/provider/keys" class="font-medium text-indigo-600 hover:text-indigo-500">
                                    <i class='fa fa-eye'></i> Keys
                                </a>
                            </div>
                        </div>
                        <div class="pt-12">
                            <button @click="signoutClicked" type="submit" :disabled="checked ? false : true" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                Sign Out
                            </button>
                        </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import LoginSplitScreen from "./../../../components/tailwind/login/LoginSplitScreen.vue";
import {useStore, mapState} from "vuex";
import LoginForm from "../../../components/apps/common/LoginForm.vue"
import LoginImage from "./../../../assets/images/scope-and-mask-purple.png" 
import RegisterImage from "./../../../assets/images/doc-heart-hands-purple.png"
import Language from "./../../../constants/language/english.js";
import RegistrationForm from '../../../components/apps/provider/RegistrationForm.vue';
import useBreakpoints from "./../../../composition/useBreakpoints.js";
import ConstantsManager from "./../../../managers/ConstantsManager.js";
export default {
    components:{LoginSplitScreen, LoginForm, RegistrationForm},
    setup () {
        let store = useStore();
        if(store.state.network.length === 0){
            store.commit("setNetwork", ConstantsManager.appConstants().TEST_NETWORK)
        }
        let providerSeed = ref(store.state.providerSeed);
        const state = reactive({
            showLogin: true,
            fading:false,
            checked:false,
        })
        let {width, height} = useBreakpoints();
        return {
            ConstantsManager,
            store,
            Language,
            width, 
            height,
            providerSeed,
            ...toRefs(state),
        }
    },
    //TODO: Turn this into a helper 
    methods:{
        showRegister(){
            this.showLogin = false;
        },
        hideRegister(){
            this.showLogin = true;
        },
        signoutClicked(){
            let response = confirm("are you sure?");
            if(response === true){
                console.log(response)
                //this.$store.dispatch("clearPracticeDetails")
                this.$store.dispatch("clearProviderAccount")
            }
        },
        navigateTo(_path){
            this.$router.push(_path)
        },
         setNetwork(_network){
            console.log(_network);
            this.$store.commit("setNetwork", _network)
        }
    },
    computed:{
       backgroundImage(){
           if(this.showLogin) return LoginImage;
           return RegisterImage;
       },
       imageClasses(){
           console.log("Height:", this.height, " Width:", this.width);
           if(this.fading){
               return "absolute inset-0 h-full w-full object-cover opacity-0"
           }
           return "absolute inset-0 h-full w-full object-cover"
       },
       isLoggedIn(){
           console.log("creds:", this.$store.state.credentials)
           console.log("creds length:", this.$store.state.credentials ? this.$store.state.credentials.length : 0 )
           if(this.$store.state.credentials === undefined) {return false}
           else if(this.$store.state.credentials.length === 0) {return false}
           return true;
       },
       loginType(){
           return ConstantsManager.appConstants().LOGIN_TYPE_PROVIDER;
       },
        currentNetwork(){
            return this.$store.state.network;
        },
       ...mapState(['unsentTransactions'])
    }
}
</script>

<style lang="scss" scoped>

</style>