<template>
    <LoggedIn >
        <template v-slot:welcomePanel >
            <div class="rounded-lg bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                <div class="bg-white p-6 text-left">
                    <container-header></container-header>
                    <div class="grid grid-cols-4">
                        <div class='col-span-1'>
                            <img class="h-24 w-24 mx-auto" src="./../../../assets/images/hospital-user.png" />
                        </div>
                        <div class='col-span-3 flex-col'>
                            <p class='text-left'> This information can be public. Make sure to share the correct verifiable information </p>
                            <div class="flex-1 sm:flex w-full">
                                <div class='p-1 m-1 flex-grow'> 
                                    <label for="practiceName" class=" text-sm font-medium text-gray-700">Practice Name</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input v-model="practiceName" 
                                        type="text" name="practiceName" 
                                        id="practiceName" 
                                        class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                        placeholder="" />
                                    </div>
                                </div>
                                <div class='p-1 m-1 flex-grow'> 
                                    <label for="businessID" class=" text-sm font-medium text-gray-700">Business ID</label>
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <OfficeBuildingIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input v-model="businessId" 
                                        type="text" 
                                        name="businessID" 
                                        id="businessID" 
                                        class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                        placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="flex-1 sm:flex w-full p-2 m-2 sm:space-x-10">
                        <div class='p-1 m-1 flex-grow'> 
                            <label for="streetAddress" class=" text-sm font-medium text-gray-700">Street Address</label>
                            <div class="mt-1 relative rounded-md shadow-sm ">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LocationMarkerIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input 
                                v-model="streetAddress" 
                                type="text" 
                                name="streetAddress" 
                                id="streetAddress" 
                                class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="" />

                            </div>
                        </div>
                        <div class='p-1 m-1 flex-grow'> 
                            <label for="streetAddress2" class=" text-sm font-medium text-gray-700">Address 2</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <LocationMarkerIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input 
                                v-model="streetAddress2" 
                                type="text" 
                                name="streetAddress2" 
                                id="streetAddress2" 
                                class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="" />
                            </div>
                        </div>        
                    </div>
                    <div class="flex-1 sm:flex w-full p-2 m-2 sm:space-x-10">
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="city" class=" text-sm font-medium text-gray-700">City</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input v-model='city' 
                                type="text" 
                                name="city" 
                                id="city" 
                                class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="" />
                            </div>
                        </div>
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="state" class=" text-sm font-medium text-gray-700">State</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input 
                                v-model="stateProvince" 
                                type="text" 
                                name="state" 
                                id="state" 
                                class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="" />
                            </div>
                        </div>
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="zipPostalcode" class=" text-sm font-medium text-gray-700">Zip/Postal Code</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input 
                                v-model="zipPostalCode" 
                                type="text" 
                                name="zipPostalcode" 
                                id="zipPostalcode" 
                                class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="" />
                            </div>
                        </div>
                        <div class='p-1 m-1 col-span-1'> 
                            <label for="country" class=" text-sm font-medium text-gray-700">Country</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <!-- <UserIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                                </div>
                                <input v-model="country" 
                                type="text" 
                                name="country" 
                                id="country" 
                                class="ring-indigo-500 ring-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" 
                                placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 sm:flex w-full p-2 m-2 sm:space-x-10">
                        <div class="flex-grow"><button @click="save" class="w-full bg-indigo-500 rounded text-white p-2 m-2"> Save</button></div>
                        <div class="flex-grow"><button @click="clear" class="w-full bg-red-500 rounded text-white p-2 m-2"> Clear</button></div>
                    </div>
                </div>
                
            </div>
        </template>
        <template v-slot:actionsPanel>
            <div class="grid grid-cols-6">
                    <div class="col-span-1">
                        <img class='h-16 w-16 sm:h-24 sm:w-24 mx-auto' src="./../../../assets/images/Credential.png" />
                    </div>
                    <div class="col-span-5">
                        <p class=" pt-6 text-left text-2xl font-semibold text-gray-600 sm:text-3xl">{{ Language.CREDENTIAL}}</p>
                        <p class="text-sm font-medium text-gray-600">{{  }}</p>
                    </div>
            </div>
            <div class='shadow'>
                <div class="p-2 m-2 sm:mx-4 sm:px-4 sm:pt-2 shadow-xl">
                    <CredentialList />
                </div>
            </div>
        </template>   
    </LoggedIn>
</template>

<script>
import { inject, reactive, ref, toRefs } from 'vue'
import {useStore} from "vuex";
import LoggedIn from "./layouts/LoggedInWide.vue"
import CredentialList from "./../../../components/apps/common/credentials/CompactList.vue"
import Run from 'run-sdk'
import { LocationMarkerIcon, UserIcon, OfficeBuildingIcon} from '@heroicons/vue/solid'
import ConstantsManager from "./../../../managers/ConstantsManager.js";
import ContainerHeader from '../../../components/apps/provider/profile/ContainerHeader.vue';



export default {
    components: {
        LoggedIn,
        CredentialList,
        UserIcon,
        LocationMarkerIcon,
        OfficeBuildingIcon,
        ContainerHeader,
    },
    async setup () {
        let store = useStore();
        let Language = inject("Language");
        const practiceName = new ref( store.state.practiceName);
        const businessId = new ref( store.state.businessId);
        const streetAddress = new ref( store.state.practiceStreetAddress);
        const streetAddress2 = new ref( store.state.practiceStreetAddress2);
        const city = new ref( store.state.practiceCity);
        const stateProvince = new ref( store.state.practiceStateProvince);
        const zipPostalCode = new ref( store.state.practiceZipPostalCode);
        const country = new ref( store.state.practiceCountry);
        const fetchCreds = async (store) => {
            console.log( store.state.providerPrivKey);
            let _network = store.state.network;
            console.log(_network)
            console.log(ConstantsManager.paymentPrivateKey(_network))
            let _run = new Run({network: _network, trust: "*", purse: ConstantsManager.paymentPrivateKey(_network), owner: store.state.providerPrivKey});
            return  await store.dispatch("fetchProviderCredentials", _run);
        }
        console.log("Credentials @ Provider Profile:", store.state.credentials)
        if(store.state.credentials.length === 0){
            let response = await fetchCreds(store);
            console.log(response);
        }
        const state = reactive({
        
        })
    
        return {
            store,
            practiceName,
            businessId,
            streetAddress,
            streetAddress2,
            city,
            stateProvince,
            zipPostalCode,
            country,
            Language,
            ...toRefs(state),
        }
    },
    computed:{
        router(){
            return this.$router;
        }
    },
    methods:{
        save(){

            let name = this.practiceName;
            let id = this.businessId;
            let address = this.streetAddress;
            let address2 = this.streetAddress2;
            let city = this.city;
            let state = this.stateProvince;
            let zip = this.zipPostalCode;
            let country = this.country;
            if( name === "" 
                || id === "" 
                || address === ""
                || city === "" 
                || state === ""
                || zip ===""
                || country ===""){
                    alert('All fields are reuqired to save.')
            }else{
                this.store.dispatch("setPracticeDetails", {name, id, address, address2, city, state, zip, country})
                alert("saved!")
            }    
        },
        clear(){
            this.$store.dispatch("setPracticeDetails", "","","","","","","","");
        },
    }
}
</script>

<style lang="scss" scoped>

</style>