<template>
    <div>
        <logged-in-wide>
            <template v-slot:welcomePanel>
                <div class='bg-white rounded-xl shadow'>
                    <div class="p-2 sm:flex sm:items-center sm:justify-between">
                        <div class="sm:flex sm:space-x-5">
                            <div class="flex-shrink-0">
                                <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="user.imageUrl" alt="" /> -->
                            </div>
                            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                <p class="text-2xl font-semibold text-gray-600 sm:text-3xl">{{ Language.HISTORY}}</p>
                                <p class="text-sm font-medium text-gray-600">{{  }}</p>
                            </div>
                        </div>
                        <div class="mt-5 flex justify-center sm:mt-0">
                        <button @click="router.push('/apps/provider/keys')" class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                            View Keys
                        </button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actionsPanel>
                <div class='w-full bg-white h-72 rounded-xl shadow flex items-center justify-center'>
                <div class="max-h-full">
                    <div class="text-red-600"> Unsent Records  </div>
                    <div class="">
                        <button @click="clearUnsent" 
                                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                Clear Unsent
                        </button>
                    </div>    
                    <div class='flex rounded-xl shadow-xl' v-for="(t, index) in decryptedUnsent" :key="t.lastAttempt">
                        <div class='flex-col m-2 p-2 text-left' >
                            <div > {{t.firstName}} {{t.lastName}} </div>
                            <div > {{t.to}} - on {{t.lastAttempt}} </div>

                        </div>
                        <div class='m-2 p-2 items-center'> 
                            <button @click="send(t, index)" class="inline-flex items-center p-2 m-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                             Send 
                             </button> 
                        </div>
                        <div class='m-2 p-2'> 
                            <button @click="remove(index)" 
                                    class="inline-flex items-center p-2 m-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                Delete 
                            </button> 
                        </div>
                    </div>
                </div>
                </div>
            </template>
        </logged-in-wide>   
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {mapState} from "vuex"
//import {useStore} from 'vuex';
import LoggedInWide from './layouts/LoggedInWide.vue'
import Language from "./../../../constants/language/english.js"
import EncryptionHelper from './../../../helpers/EncryptionHelper.js'
import Run from 'run-sdk'
import ConstantsManager from "./../../../managers/ConstantsManager.js"
import VXTransactionHelper from './../../../helpers/VXTransactionHelper.js'
export default {
  components: { LoggedInWide },
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    methods:{
        clearUnsent(){
            this.$store.commit("clearUnsentTransactions");
        },
        async send(tx_options, index){
            console.log(tx_options)
            let _network = this.$store.state.network;
            let _run = new Run({network:_network, trust: "*", purse: ConstantsManager.paymentPrivateKey(_network), owner: this.$store.state.providerPrivKey});
           try{
                 await _run.activate();
                await _run.sync();
            }
            catch(err){
                console.log("Error Syncing Run", err); 
                 if(err.message.includes('timeout') || err.message.includes('fetch')){
                    alert('timeout error');
                 }
            }
            let selectedCredential = null; 
            let _class; 
            let _tx;

            try{
                selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
                _class = await _run.load(ConstantsManager.vaccinationRecordClassLocation(_network));
                _class.sync()
               
            }catch(err){
                console.log(err);
                return;
            }
            try{
                 _tx = VXTransactionHelper.setupVXRecordTransaction(_run, selectedCredential, _class, tx_options.to, tx_options);
                console.log("This is a transaction", _tx);
            }catch(err){
                console.log(err)
                return;
            }
             try{
                let result =  await _tx.publish();
                if(result){console.log(result);}
                alert(this.Language.SAVE_RECORD_SUCCESS_MESSAGE);
                this.$store.commit("removeUnsentTransaction", index)

             }catch(err){
                if(err.message.includes('timeout') || err.message.includes('fetch')){
                    alert('timeout error');
                }
             }
        },
        remove(index){
             this.$store.commit("removeUnsentTransaction", index)
        }
    },
    computed:{
        decryptedUnsent(){
            let _unsent = [];
            let _salt = this.$store.state.providerPrivKey;
            this.$store.state.unsentTransactions.forEach(element => {
                _unsent.push(EncryptionHelper.decryptOptions(_salt, element))
            });
            return _unsent;
        },
        ...mapState(["unsentTransactions"])
    }
}
</script>

<style lang="scss" scoped>

</style>