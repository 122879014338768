<template>
    <div class="col-span-1">
        <div class="flex items-start group "> 
            <div class="flex w-full h-28 bg-white z-1" :style="bgImage" > 
                <div class="flex w-full items-center justify-center">
                    <i :class='icon'></i>
                </div>
            </div>
           
        </div>
        </div>
        <div class="col-span-2 shadow-xl rounded-xl">
        <div class="text-left p-2 m-2 pt-5">
            <div class="text-xs font-semibold tracking-wide uppercase">{{ title }}</div>
            <div class="text-sm text-gray-500">{{ step.description }} {{step.status}}</div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import UIHelper from "./../../../../helpers/UIHelper.js"
const greenCircleSrc = '/images/circles/GreenGreenDoubleCircle400.png';
//const blueCircleSrc =  '/images/circles/BluePurpleDoubleCircle400.png';
const pinkCircleSrc =  '/images/circles/PinkPurpleDoubleCircle400.png';
const grayCircleSrc =  '/images/circles/GrayGrayDoubleCircle400.png';
export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            UIHelper,
            greenCircleSrc,
            ...toRefs(state),
        }
    },
    methods:{
        tokenCount(){
            return this.$store.state.tokens ? this.$store.state.tokens.length : 0;
        }
    },
    computed:{
        bgImage(){
            if(this.step.status === "complete"){return UIHelper.inlineBgImage(greenCircleSrc, 100, 100)}
            if(this.step.status === "pending"){return UIHelper.inlineBgImage(grayCircleSrc, 100, 100)}
            return UIHelper.inlineBgImage(pinkCircleSrc, 100, 100)
        },
        title(){
            if(this.step.status === "complete"){return "Complete";}
            return "Pending";
        },
        icon(){
            if(this.step.status === "complete"){return "text-2xl fa fa-check text-green-400 pt-50"}
            return "fa fa-question text-gray-400 pt-50"
        },
        timelineColor(){
            if (this.step.status === 'complete') {return "bg-green-400"}
            return "bg-indigo-600"
        }
    },
    props:["step", "stepIdx", "length"]
}
</script>

<style lang="scss" scoped>

</style>