<template>
<div> 
<header-and-footer>
    <template v-slot:header>
        
        <div class="pb-5 border-gray-200 sm:flex sm:items-center sm:justify-between">
            <nav class="flex" aria-label="Breadcrumb">
                <ol class="bg-white rounded-md shadow px-6 flex space-x-4">
                <li class="flex">
                    <div class="flex items-center">
                    <a href="#" class="text-gray-400 hover:text-gray-500">
                        <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </a>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <a class="ml-4 text-sm font-medium text-indigo-500 hover:text-gray-700" >Credential Factories</a>
                    </div>
                </li>
                </ol>
            </nav>
            <div class="mt-3 sm:mt-0 sm:ml-4">
            <router-link to="/apps/admin/factory/new" type="button" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Create A Factory
            </router-link>
            </div>
        </div>
    </template>
    <template v-slot:content>
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <ul v-if="factoryList[0] !== null" class="divide-y divide-gray-200">
            <li v-for="factory in factoryList" :key="factory.location" class='p-1 m-1'>
                <a :href="`/apps/admin/factory/details?id=${factory.location}`" class="block hover:bg-gray-50">
                <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                    <div class="flex-shrink-0">
                        <img class="h-12 w-12 rounded-full" :src="factory.logoUrl" alt="" />
                    </div>
                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                        <p class="text-sm font-medium text-indigo-600 truncate">{{factory.organization}} {{ factory.state }}, {{ factory.country }}</p>
                        <p class="mt-2 flex items-center text-sm text-gray-500">
                            <GlobeIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span class="truncate">{{ factory.url }}</span>
                        </p>
                        </div>
                        <div class="hidden md:block">
                        <div>
                            <p class="text-sm text-gray-900">
                            <!-- Applied on
                            {{ ' ' }}
                            <time :datetime="application.date">{{ application.dateFull }}</time> -->
                            </p>
                            <p class="mt-2 flex items-center text-sm text-gray-500">
                            <CheckCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                            {{ factory.description }}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
                </a>
            </li>
            </ul>
        </div>
    </template>
    <template v-slot:footer></template>

</header-and-footer>
</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useStore} from 'vuex';
import Run from "run-sdk";
import Utilities from "./../../../../helpers/Utilities.js"
import {ChevronRightIcon, CheckCircleIcon, GlobeIcon} from '@heroicons/vue/outline'
import HeaderAndFooter from "./../../../../components/tailwind/panels/HeaderAndFooter.vue";

export default {
    async setup () {
        let store = useStore(); 
        let { adminPrivKey } = store.state;
        let {credFactories } = store.state;
        let newKey = adminPrivKey

         let d = Utilities.dateString(0);
        console.log(d);
        let run;
        if(store.state.adminPrivKey !== ""){
            run = new Run({trust:"*", owner: adminPrivKey , network: store.state.network})
            await store.dispatch("fetchCredentialFactories", run, true);
            console.log("Cred Factories @ List Mounted:", credFactories)
        }else{
            console.log("admin private key is not set at ListFactories mounted")
        }

        const state = reactive({
            count: 0,
            adminPrivKey,
            newKey,
            credFactories
        })
    
        return {
            ...toRefs(state),
            store
        }
    },
    async mounted(){
        let d = Utilities.dateString(0);
        console.log(d);
        let run;
        if(this.$store.state.adminPrivKey !== ""){
            run = new Run({trust:"*", owner: this.$store.state.adminPrivKey , network: this.$store.state.network})
            await this.$store.dispatch("fetchCredentialFactories", run, true);
            console.log("Cred Factories @ List Mounted:", this.$store.state.credFactories)
        }else{
            console.log("admin private key is not set at ListFactories mounted")
        }
        
    },
    methods:{
        updateAdminPK(){
            this.$store.commit("setAdminPrivKey", this.newKey)
            let run = new Run({trust:"*", owner:  this.newKey, network: this.$store.state.network})
            this.$store.dispatch("fetchCredentialFactories", run, true);
        }
    },
    computed:{
        factoryList(){
            return this.$store.state.credFactories
        }
    },
    components:{
        ChevronRightIcon,
        CheckCircleIcon,
        GlobeIcon,
        HeaderAndFooter
    }
}
</script>

<style lang="scss" scoped>

</style>