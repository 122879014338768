import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import Icons from '../views/Icons.vue'
import Menus from "../views/tailwind/examples/TailwindMenus.vue"
import AdminLogin from './../views/apps/admin/Login.vue'
import AdminHome from "./../views/apps/admin/Home.vue"
import CredentialFactoryList from './../views/apps/admin/factories/List.vue'
import NewCredentialFactory from './../views/apps/admin/factories/New.vue'
import CredentialFactoryDetails from './../views/apps/admin/factories/Details.vue'
import CredentialList from './../views/apps/admin/credentials/List.vue'
import NewRecord from "./../components/apps/admin/records/New.vue"
import CheckVerify from "./../views/apps/verify/Check.vue"
import PatientLogin from "./../views/apps/patient/Login.vue"
import PatientHome from "./../views/apps/patient/Home.vue"
import PatientCovid from "./../views/apps/patient/Covid.vue"
import PatientProfile from "./../views/apps/patient/Profile.vue"
import PatientKeys from "./../views/apps/patient/Keys.vue"
import ProviderLogin from './../views/apps/provider/Login.vue'
import ProviderCredentials from './../views/apps/provider/Credentials.vue'
import ProviderHome from './../views/apps/provider/Home.vue'
import ProviderProfile from './../views/apps/provider/Profile.vue'
import ProviderRecordNew from "./../views/apps/provider/records/New.vue";
import ProviderKeys from "./../views/apps/provider/Keys.vue"
import PointOfCare from "./../views/apps/provider/PointOfCare.vue"
import Aftercare from "./../views/apps/provider/Aftercare.vue"
import Aftercare2 from "./../views/apps/provider/Aftercare2.vue"
import ProviderAlerts from "./../views/apps/provider/Alerts.vue"
import ProviderHistory from "./../views/apps/provider/History.vue"
import LiteHome from "./../views/apps/lite/Home.vue"
import LiteBuy from "./../views/apps/lite/Buy.vue"
import LiteSuccess from "./../views/apps/lite/Success.vue"
import LiteCreate from "./../views/apps/lite/Create.vue"
import LiteManager from "./../views/apps/lite/Manager.vue"
import AboutLite from "./../views/apps/lite/About.vue"
import LiteCompany from "./../views/apps/lite/Company.vue"
import LitePrice from "./../views/apps/lite/Price.vue"
import PatientPhoto from "./../views/apps/patient/Photo.vue"

const routes = [
  // { path: '/', name: 'Home', component: Home },
  { path: '/', name: 'Home', component: LiteHome },
  { path: '/tailwind/examples/menus', name: 'Menus', component: Menus },
  { path: '/icons', name: 'Icons', component: Icons },
  { path: '/apps/admin/login', name: 'Admin Login', component: AdminLogin },
  { path: '/apps/admin/home', name: 'Admin Home', component: AdminHome },
  { path: '/apps/admin/factory-list', name: 'Factory List', component: CredentialFactoryList },
  { path: '/apps/admin/factory/new', name: 'New Credential Factory', component: NewCredentialFactory },
  { path: '/apps/admin/factory/details', name: 'Credential Factory Details', component: CredentialFactoryDetails },
  { path: '/apps/admin/credential-list', name: 'Credential List', component: CredentialList },
  { path: '/apps/admin/record/new', name: 'New Record', component: NewRecord },
  { path: '/apps/verify/check', name: 'CheckVerify', component: CheckVerify },
  { path: '/apps/provider/Login', name: 'Provider Login', component: ProviderLogin },
  { path: '/apps/provider/Home', name: 'Provider Home', component: ProviderHome },
  { path: '/apps/provider/credentials', name: 'My Credentials', component: ProviderCredentials },
  { path: '/apps/provider/profile', name: 'My Profile', component: ProviderProfile },
  { path: '/apps/provider/keys', name: 'Provider Keys', component: ProviderKeys },
  { path: '/apps/provider/records/new', name: 'Provider - New Record', component: ProviderRecordNew },
  { path: '/apps/provider/poc', name: 'Point of Care', component: PointOfCare },
  { path: '/apps/provider/aftercare', name: 'Aftercare', component: Aftercare },
  { path: '/apps/provider/aftercare2', name: 'Aftercare2', component: Aftercare2 },
  { path: '/apps/provider/alerts', name: 'Alerts', component: ProviderAlerts },
  { path: '/apps/provider/history', name: 'History', component: ProviderHistory },
  { path: '/apps/patient/login', name: 'Patient Login', component: PatientLogin },
  { path: '/apps/patient/home', name: 'Patient Home', component: PatientHome },
  { path: '/apps/patient/covid', name: 'Covid Status', component: PatientCovid },
  { path: '/apps/patient/profile', name: 'Profile', component: PatientProfile },
  { path: '/apps/patient/keys', name: 'Patient Keys', component: PatientKeys },
  { path: '/apps/patient/photo', name: 'VXPASS Photo', component: PatientPhoto },
  { path: '/apps/lite/home', name: 'VXLITE Home', component: LiteHome },
  { path: '/apps/lite/buy', name: 'VXLITE Buy', component: LiteBuy },
  { path: '/apps/lite/payment-success', name: 'VXLITE Success', component: LiteSuccess },
  { path: '/apps/lite/create-record', name: 'VXLITE Create', component: LiteCreate },
  { path: '/apps/lite/manager', name: 'VXLITE Manager', component: LiteManager },
  { path: '/apps/lite/about', name: 'About VXLITE', component: AboutLite },
  { path: '/apps/lite/company', name: 'VXPASS Company', component: LiteCompany },
  { path: '/apps/lite/price', name: 'VXPASS Price', component: LitePrice },
  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
