
const Message = require('bsv/message')
const bsv = require("bsv");
const Mnemonic = require("bsv/mnemonic");
const ConstantsManager = require('./../managers/ConstantsManager.js');
module.exports = {
    credentialTrust(_network){
        return [
        ConstantsManager.credentialClassOrigin(_network).slice(0, 64), 
        ConstantsManager.credentialClassLocation(_network).slice(0, 64), 
        ConstantsManager.medicalCredentialFactoryClassLocation(_network).slice(0, 64), 
        ConstantsManager.medicalCredentialFactoryClassOrigin(_network).slice(0, 64)]
    },
    dateString(days){
        var date = new Date();
        if(days != 0 )date.setDate(date.getDate() + days);
        else date.setDate(date.getDate() + days);
        return date.toString();
    },
    addDays(_startDate, days){
        console.log("days", days);
        let _date = new Date();
        let response = _date.setDate( _startDate.getDate() + days);
        console.log("_date", response);
        return new Date(response);
    },
    daysUntil(inputDate){ 
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var firstDate = new Date();
        return Math.round(((inputDate.getTime() - firstDate.getTime())/(oneDay)));
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    verifyRecordSig(_record, _network){
        if(_network === undefined){_network = "test"}
        //TODO: Add Verification Levels instead of returning true/false return 0 - none, 1- self-attested, 2- aftercare, 3- POC. 
        try{
            let message = _record.mfg + _record.ndc + _record.lot + _record.expiry_mm_yy + _record.distributed.replace("-", "/");
            console.log("Message before verification", message)
            let v = Message.verify(message, _record.cred.owner, _record.signature);
            if(v && _record.cred.factory.constructor.origin === ConstantsManager.medicalCredentialFactoryClassOrigin(_network)){return true;}
        }catch(err){
            return false;
        }
    },
    verifyRecordSigwName(_record, first, middle, last, _network){
        //TODO: Add Verification Levels instead of returning true/false return 0 - none, 1- self-attested, 2- aftercare, 3- POC. 
        let _response = false;
        if(_network === undefined){_network = "test"}
        try{
            let message = _record.mfg + _record.ndc + _record.lot + _record.expiry_mm_yy + _record.distributed + first + middle + last;
            let v = Message.verify(message, _record.cred.owner, _record.nameSig);
            if(v && _record.cred.factory.constructor.origin === ConstantsManager.medicalCredentialFactoryClassOrigin(_network)){_response=true;}    
        }catch(err){console.log(err);}
        return _response;
    },
    /////
    getRandomAccount(){
        const mnemonic = Mnemonic.fromRandom();
        const hdPrivKey = bsv.HDPrivateKey.fromSeed(mnemonic.toSeed(), "testnet")
        const someKey = hdPrivKey.deriveChild("m/0/0/0") // Private key
        const privateKey = someKey.privateKey
        const address = privateKey.toAddress()
        return {seed: mnemonic.toString(), hdPrivateKey: hdPrivKey.toString(), derivedKey: someKey.toString(), privateKey: privateKey.toString(), address: address.toString()}
    },

    getAccountFromSeed(seed){
        const mnemonic = Mnemonic.fromString(seed);
        const hdPrivKey = bsv.HDPrivateKey.fromSeed(mnemonic.toSeed(), "testnet")
        const someKey = hdPrivKey.deriveChild("m/0/0/0") // Private key
        const privateKey = someKey.privateKey
        const address = privateKey.toAddress()
        return {seed: mnemonic.toString(), hdPrivateKey: hdPrivKey.toString(), derivedKey: someKey.toString(), privateKey: privateKey.toString(), address: address.toString()}
    },

    findRecords(_run){
        let tokens = []
        let _network = _run._network;
        let _jigs = _run.inventory.jigs;
        console.log({_jigs})
        //TODO: Replace this jig.cred === null with constructor.origin === the factory origin. 
        _jigs.forEach( jig => {
            if((jig.cred && jig.cred.constructor.origin === ConstantsManager.credentialClassOrigin(_network)) 
            || jig.cred === null && jig.signature && jig.nameSig){
                console.log("This is a VXRecord");
                tokens.push( jig)
            }
        });
        return tokens;
    }
}