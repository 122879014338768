<template>
    <div class="col-span-1">
        <div class="flex-1 items-start group "> 
            <div class="flex w-full h-12 mt-2 bg-white rounded-full z-1" :style="bgImage" > 
                <div class="flex w-full items-center justify-center">
                    <i v-if='step.status !== "current"' :class="icon"></i>
                   <div v-else class='font-bold bg-gradient-to-b from-pink-500 to-purple-500 rounded-full text-white p-1 m-1'> 
                       {{ step.days }}
                   </div>
                </div>
            </div>
            <div class='flex'>
                <div class='flex-grow'></div>
                <div class=" items-center mx-auto"> 
                    <div v-if="(stepIdx !== length)" :class="`top-4 left-4 w-0.5 h-20 ${timelineColor}`" aria-hidden="true" />
                </div>
                <div class='flex-grow'></div>
            </div>
        </div>
    </div>
        <div class="col-span-2 shadow-xl rounded-xl">
        <div class="text-left m-2 p-2 pt-5">
            <div class="text-xs font-semibold tracking-wide uppercase">{{ step.name }}</div>
            <div v-if="step.days > 0" class="text-sm text-gray-500">{{ step.days }} days</div>
            <div class="text-sm text-gray-500">{{ step.description }}</div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import UIHelper from "./../../../../helpers/UIHelper.js"
import ConstantsManager from './../../../../managers/ConstantsManager.js';
const greenCircleSrc = '/images/circles/GreenGreenDoubleCircle400.png';
const blueCircleSrc =  '/images/circles/BluePurpleDoubleCircle400.png';
const grayCircleSrc =  '/images/circles/GrayGrayDoubleCircle400.png';
export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    computed:{
         bgImage() {
             let img = "";
            if(this.step.status === "complete"){
                img = greenCircleSrc;
            }else if(this.step.status === "pending"){
                img = grayCircleSrc;
            }else {img = blueCircleSrc;}
            return UIHelper.inlineBgImage(img, 50, 50);
        },
        icon(){
            if(this.step.status === "complete"){return "fa fa-check text-green-400 pt-50"}
            else if(this.step.status === "current"){return "text-purple-600 pt-50"}
            if(this.tokenCount === 0){return "fa fa-calendar text-gray-400 pt-50"}
            if(this.tokenCount === 1){
                 if(this.$store.state.patientTokens[0].mfg === ConstantsManager.vaccineManufacturers().Janssen){
                     return "fa fa-check text-green-400 pt-50"
                 }else{
                     return "fa fa-calendar text-gray-400 pt-50"
                 }
            }
             return "fa fa-check text-green-400 pt-50"
        },
         tokenCount(){
            return this.$store.state.tokens ? this.$store.state.tokens.length : 0;
        },
        timelineColor(){
            if(this.step.status == "complete") return "bg-green-500"
            if(this.step.status == "current") return "bg-indigo-500"
            return "bg-gray-500"
        }
    },
    props:['step', 'stepIdx', 'length']
}
</script>

<style lang="scss" scoped>

</style>