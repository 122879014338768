const axios  = require('axios');
module.exports = {
    getBaseUrl(){
        //return 'http://localhost:3000';
        return 'https://hidden-lowlands-26704.herokuapp.com'
    },
    async checkCode(code){
        let res = await axios.get(`${this.getBaseUrl()}/redemption_codes/search/${code}.json`);
        let data = res.data;
        return data;
    },
    async burnCode(code, address){
        let options = {address: address, burned: true}
        let res = await axios.put(`${this.getBaseUrl()}/redemption_codes/burn/${code}/${address}.json`, options);
        return res.data;
    },
    async createCredentialRequest(name, national_id, provider_address, state, credential_authority){
        let options = {provider_address: provider_address, name: name, national_id: national_id, state:state, credential_authority: credential_authority}
        console.log(options)
    }
}