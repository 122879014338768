<template>
    <div>
        <new-layout>
            <template v-slot:pageHeader >
                <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div class="flex items-center space-x-5">
                    <div class="flex-shrink-0">
                        <div class="relative">
                        <img class="h-16 w-16 rounded-full" :src="`${store.state.selectedCredential.factory.logoUrl}`" alt="" />
                        <span class="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                        </div>
                    </div>
                    <div>
                        <h1 class="text-2xl font-bold text-gray-900 sm:text-left">{{store.state.selectedCredential.factory.organization}}</h1>
                        <p class="text-left text-sm font-medium text-gray-500 ">View Organization Contract </p>
                        <a target="_blank" rel="noreferrer noopen" :href="`https://run.network/explorer/?query=${store.state.selectedCredential.factory.location}&network=test`" class="text-left  text-gray-900 xs:text-xs break-all">{{store.state.selectedCredential.factory.location}}</a>
                    </div>
                    </div>
                    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    <button @click="createPatient" type="button" class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                        Create Patient
                    </button>
                    <input v-model="patientAddress" class='min-w-sm rounded shadow' />
                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                        Set Address
                    </button>
                    </div>
                </div>
            </template>
            <template v-slot:leftPanel> 
                <section aria-labelledby="applicant-information-title">
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" class="text-left text-lg leading-6 font-medium text-gray-900">
                            Patient Record Information
                            </h2>
                            <p class="text-left mt-1 max-w-2xl text-sm text-gray-500">
                            Detailing the patients covid protocol completion. 
                            </p>
                        </div>
                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <div class="bg-white shadow sm:rounded-lg">
                                <div class="px-4 py-5 sm:p-6">
                                    <h3 class="text-3xl leading-6 font-medium text-gray-900 text-left">
                                        Patient Details
                                    </h3>
                                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            This is not stored on the blockchain, but is needed to create the patients digital signature. 
                                        </p>
                                    </div>
                                    <div class="mt-5 sm:flex sm:items-center rounded-xl bg-gray-200 p-2 m-2">
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="birthday" class="block text-sm font-medium text-gray-700">First Name</label>
                                        <input v-model="firstName" type="text" name="firstName" id="firstName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Jane" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="middleName" class="block text-sm font-medium text-gray-700">Middle Name</label>
                                        <input v-model="middleInitial" type="text" name="middleName" id="middleName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="I" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="lastNmae" class="block text-sm font-medium text-gray-700">Last Name</label>
                                        <input v-model="lastName" type="text" name="lastName" id="lastName" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Doe" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="birthday" class="block text-sm font-medium text-gray-700">Birthday</label>
                                        <input v-model="birthday" type="date" name="birthday" id="birthday" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                        </div>
                                    </div>
                                    <div class="text-2xl pt-4 mt-4 font-normal text-transparent  bg-clip-text bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-800 ">1st Dose X</div>
                                    <div class="bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-800 rounded-xl">
                                        
                                        <div class="mt-1 sm:flex sm:items-center rounded-xl p-2">
                                            <div class="p-1 w-full flex-grow sm:max-w-xs">
                                                <Listbox as="div" v-model="selectedManufacturer">
                                                    <ListboxLabel class="block text-sm font-medium text-gray-100">
                                                    Manufacturer
                                                    </ListboxLabel>
                                                    <div class="mt-1 relative">
                                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                            <span class="block truncate">{{ selectedManufacturer }}</span>
                                                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </span>
                                                        </ListboxButton>

                                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                            <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer }">
                                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                                                <span :class="[selectedManufacturer ? 'font-semibold' : 'font-normal', 'block truncate']">
                                                                    {{ mfg}}
                                                                </span>

                                                                <span v-if="selectedManufacturer" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                                </li>
                                                            </ListboxOption>
                                                            </ListboxOptions>
                                                        </transition>
                                                    </div>
                                                </Listbox>
                                            </div>
                                            <div class="p-1 w-full sm:max-w-xs">
                                            <label for="lotId" class="block text-sm font-medium text-gray-100">Lot ID</label>
                                            <input v-model="lotId" type="text" name="lotId" id="lotId" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="AA65TG" />
                                            </div>
                                            <div class="p-1 w-full sm:max-w-xs">
                                            <label for="lotId" class="block text-sm font-medium text-gray-100">Date Distributed</label>
                                            <input v-model="dose1Date" type="date" name="dose1date" id="dose1date" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                            </div>
                                            
                                        </div>
                                        <div class="mt-1 sm:flex sm:items-center">
                                        <div class="p-1 w-full sm:min-w-md">
                                        <label for="site1Name" class="block text-sm font-medium text-gray-100">Site Name</label>
                                        <input v-model="site1Name" type="text" name="site1Name" id="site1Name" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Quiet Waters Park" />
                                        </div>
                                    </div>
                                    </div>
                                    <div class="text-2xl pt-4 mt-4 font-extrabold text-transparent  bg-clip-text bg-gradient-to-r from-pink-500 via-pink-600 to-pink-700 ">2nd Dose </div>
                                    <div class="bg-gradient-to-r from-pink-500 via-pink-600 to-pink-700 rounded-xl">
                                      <div class="mt-1 sm:flex sm:items-center rounded-xl p-2">
                                        <div class="p-1 w-full flex-grow sm:max-w-xs ">
                                            
                                            <Listbox as="div" v-model="selectedManufacturer2">
                                                <ListboxLabel class="block text-sm font-medium text-gray-100">
                                                Manufacturer
                                                </ListboxLabel>
                                                <div class="mt-1 relative">
                                                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                        <span class="block truncate">{{ selectedManufacturer2 }}</span>
                                                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </ListboxButton>

                                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                        <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer2 }">
                                                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                                            <span :class="[selectedManufacturer2 ? 'font-semibold' : 'font-normal', 'block truncate']">
                                                                {{ mfg}}
                                                            </span>

                                                            <span v-if="selectedManufacturer2" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                            </li>
                                                        </ListboxOption>
                                                        </ListboxOptions>
                                                    </transition>
                                                </div>
                                            </Listbox>
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="lotId2" class="block text-sm font-medium text-gray-100">Lot ID</label>
                                        <input v-model="lotId2" type="text" name="lotId2" id="lotId2" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="AA65TG" />
                                        </div>
                                        <div class="p-1 w-full sm:max-w-xs">
                                        <label for="doseDate2" class="block text-sm font-medium text-gray-100">Date Distributed</label>
                                        <input v-model="doseDate2" type="date" name="doseDate2" id="doseDate2" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                        </div>
                                        
                                    </div>
                                    <div class="mt-1 sm:flex sm:items-center">
                                        <div class="p-1 w-full sm:min-w-md">
                                        <label for="siteName2" class="block text-sm font-medium text-gray-100">Site Name</label>
                                        <input v-model="siteName2" type="text" name="siteName2" id="siteName2" class="mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Quiet Waters Park" />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <a @click="showConfirm = true" class="block bg-gray-50 text-sm font-medium text-indigo-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">Save Vaccination Records</a>
                        </div>
                    </div>
                </section>
                <div v-if="showConfirm">
                    <two-button-wide name="confirmModal" @modalConfirmed="confirmFromModal" @modalCanceled="showConfirm = false" title="Saving " message="Please Confirm the details before saving the record." confirmText="Confirm">
                        <template v-slot:content  >
                            <div class=" shadow-xl">
                            <div class="flex flex-col bg-white">
                                <div class="text-md p-1 m-1 ">
                                    {{firstName}}
                                    {{middleInitial}}
                                    {{lastName}}
                                    {{birthday}}
                                    
                                </div>
                                <div class="text-xs text-gray-600 underline"> ID: </div>
                                <div>  {{patientAddress}}</div>
                                
                                <div class="flex flex-1">
                                <div class="text-md p-1 m-1 flex-grow shadow bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-800 text-white">
                                    <div class="text-xs  underline">Dose 1 </div>
                                    <div class="font-extrabold" > {{selectedManufacturer}} </div>
                                    <div> {{lotId}}</div> 
                                    <div>{{dose1Date}}</div>
                                   <div>{{site1Name}} </div>
                                </div>
                                <div class="text-md p-1 m-1 flex-grow shadow bg-gradient-to-r from-pink-500 via-pink-600 to-pink-700 text-gray-50">
                                <div class="text-xs underline">Dose 2 </div>
                                <div class="font-extrabold "> {{selectedManufacturer}} </div>
                                <div> {{lotId2}}</div>
                                <div> {{doseDate2}}</div>
                                <div>{{siteName2}}</div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </template>
                    </two-button-wide>
                </div>
            </template>
            <template v-slot:sideTitle>
                <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Patient Data Status</h2>
            </template>
            <template v-slot:rightPanel>
                <ul class="-mb-8">
                <li v-for="(item, itemIdx) in timeline" :key="item.id">
                  <div class="relative pb-8">
                    <span v-if="(itemIdx !== timeline.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    <div class="relative flex space-x-3">
                      <div>
                        <span :class="[item.type.bgColorClass, 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white']">
                          <component :is="item.type.icon" class="w-5 h-5 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          <p class="text-sm text-gray-500">
                             <a href="#" class="font-medium text-gray-900">{{ item.target }}</a> {{ item.lotId }}
                          </p>
                          <p class="text-sm text-gray-500">{{item.site}}</p>
                        </div>
                        <div class="text-right text-sm whitespace-nowrap text-gray-500">
                          <time :datetime="item.datetime">{{ item.date }}</time>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </template>
            <template v-slot:rightFooter >
                 <button type="button" @click="showConfirm = true" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Save Records
                    </button>
            </template>
        </new-layout>
    </div>
</template>

<script>
import { ref, reactive,
toRefs 
} from 'vue'
import {useStore} from "vuex"
import NewLayout from "./NewLayout.vue"
import {
  Listbox, 
  ListboxButton, 
  ListboxLabel, 
  ListboxOption, 
  ListboxOptions
} from '@headlessui/vue'
import {
  
  CheckIcon,
  SelectorIcon,
  UserIcon,
  ThumbUpIcon,
  QuestionMarkCircleIcon
} from '@heroicons/vue/solid'
import Utilities from '../../../../helpers/Utilities'
import Run from "run-sdk";
import ConstantsManager from "./../../../../managers/ConstantsManager.js"
import VXTransactionHelper from "./../../../../helpers/VXTransactionHelper.js";
import TwoButtonWide from '../../../tailwind/modals/TwoButtonWide.vue'
const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  unknown: { icon: QuestionMarkCircleIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: ThumbUpIcon, bgColorClass: 'bg-blue-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
}
export default {
    setup () {
        let store = useStore();
        console.log(store.state.selectedCredential)
        const selectedManufacturer = ref(ConstantsManager.vaccineManufacturers()[0])
        const selectedManufacturer2 = ref(ConstantsManager.vaccineManufacturers()[0])
      
       let manufacturers = ConstantsManager.vaccineManufacturers();
       let state = reactive({
           firstName: "",
           middleInitial: "",
           lastName: "",
           birthday: null,
           lotId: "",
           dose1Date: new Date(),
           site1Name:"",
           lotId2: "",
           doseDate2: new Date(),
           siteName2:"",
           patientAddress: store.state.patientAddress,
           showConfirm: false,
       })
    
        return {
            manufacturers,
            store,
            selectedManufacturer,
            selectedManufacturer2,
            ...toRefs(state)
        }
    },
    methods:{
        async createRecords(){
            try{
                let _network = this.$store.state.network;
                let _run = new Run({network: _network, trust: "*", purse: ConstantsManager.paymentPrivateKey(_network), owner: this.$store.state.providerPrivKey});
                let _ndc = ConstantsManager.ndcCode(1, this.selectedManufacturer);
                let options1 = {manufacturer: this.selectedManufacturer, ndc: _ndc, lot: this.lotId, expiry: Utilities.dateString(+365), distributed: this.dose1Date, site:this.site1Name, firstName: this.firstName.trim(), mi: this.middleInitial.trim(), lastName: this.lastName.trim()}
                let selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
                let _class = await _run.load(ConstantsManager.vaccinationRecordClassLocation(_network));
                await _class.sync()
                _ndc = ConstantsManager.ndcCode(2, this.selectedManufacturer2);
                let _tx = VXTransactionHelper.setupVXRecordTransaction(_run, selectedCredential, _class, this.patientAddress, options1);
                let options2 = {manufacturer: this.selectedManufacturer2, ndc: _ndc, lot: this.lotId2, expiry: Utilities.dateString(+365), distributed: this.doseDate2, site:this.siteName2, firstName: this.firstName.trim(), mi: this.middleInitial.trim(), lastName: this.lastName.trim()}
                _tx =  VXTransactionHelper.addRecordToTransaction(_run, _tx, selectedCredential, _class, this.patientAddress, options2);
                let result =  await _tx.publish();
                if(result){console.log(result);}
        
                // let toVerify = await _run.load(result + "_o1");
                // let verified = Utilities.verifyRecordSig(toVerify);
                }catch(err){console.log(err); alert(err)}
                this.showConfirm = false;
        },
        async confirmFromModal(){
            if(this.addresss === ""){alert("Pateint Blockchain Account Not Created")}
            console.log("Confirmed")
            await this.createRecords();
        },
        createPatient(){
            let _run = new Run({network: this.$store.state.network, trust: "*"});
            this.patientAddress = _run.owner.address;
            console.log(this.patientAddress);
            this.$store.commit("setPatientAddress", _run.owner.address);
            this.$store.commit("setPatientPrivKey", _run.owner.privkey);
            //this.$store.commit("setPatientSeed", random.seed);
            //console.log(_run.owner.privkey);
        },
        setAddress(){
            this.$store.commit('setPatientAddress', this.patientAddress);
        }
    },
    computed:{
        timeline(){
            let nameOrBirthdayMissing = (this.firstName === "" || this.lastName === "" || this.birthday < new Date());
            let firstComplete = (this.selectedManufacturer !== "" && this.lotId !== "" )
            let secondComplete = (this.selectedManufacturer !== "" && this.lotId2 !== "" )
            console.log("Stored:", this.dose1Date.toString());
            let d1 = new Date(this.dose1Date.toString() + "T00:00:00");
            let d1String = new Date(d1).toString();
            let d2 = new Date(this.doseDate2.toString() + "T00:00:00");
            let d2String = new Date(d2).toString();
            let localDate1; 
            if(d1String !== "Invalid Date" ){localDate1 =  d1.toDateString('en-us')} else{firstComplete = false}
            let localDate2;
            if(d2String !== "Invalid Date" ){localDate2 = d2.toDateString('en-us')} else{secondComplete = false}
        
            let first = {id:1, type: nameOrBirthdayMissing ? eventTypes.unknown : eventTypes.advanced, target: (this.firstName + " " + this.middleInitial + " " + this.lastName), lotId: this.birthday, date: "", datetime:"" }
            let second = {id:2, type: firstComplete ? eventTypes.advanced : eventTypes.unknown , target: this.selectedManufacturer, lotId: "lotID: " + this.lotId, date: localDate1, datetime:"", site: this.site1Name}
            let third = {id:3, type: secondComplete ? eventTypes.advanced : eventTypes.unknown , target: this.selectedManufacturer2, lotId: "lotID: " + this.lotId2, date: localDate2, datetime:"", site: this.siteName2}
             const _timeline = [
                first,
                second,
                third,
                ]
                return _timeline
        },
    },
    components: {
        NewLayout,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
        TwoButtonWide,
        QuestionMarkCircleIcon
        
    }
}
</script>

<style lang="scss" scoped>

</style>