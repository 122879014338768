<template>
    <div>
            <div class='selector shadow'>
                <Listbox as="div" v-model="selectedManufacturer" >
                    <div class="mt-1 relative">
                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                        <span class="block truncate">{{ selectedManufacturer }}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>
                    <label for="manufacturer" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">{{Language.MANUFACTURER}}</label>
                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer }">
                            <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <span :class="[selectedManufacturer ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ mfg }}
                            </span>

                            <span v-if="selectedManufacturer" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute z-8 inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                            </li>
                        </ListboxOption>
                        </ListboxOptions>
                    </transition>
                    </div>
                </Listbox>
        </div>
        <div class='grid grid-cols-1 md:grid-cols-3 space-x-1 rounded '>
            <div class="align-bottom pt-2">
            <div class="mt-4 relative col-span-1 border border-gray-300 rounded-md px-3 py-4 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 shadow">
                <label for="lotID" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">{{Language.LOT_ID}}</label>
                <input v-model="lotID" @change="lotIDChanged" type="text" name="lotID" id="lotID" class="ring-indigo-500 ring-1 block w-full rounded  p-1 pt-1 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" style='border:none' placeholder="LOT ID" />
            </div>
            </div>
             <div class="mt-4 relative col-span-2  border border-gray-300 rounded-md px-3 py-1 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 shadow">
                <label :for="`distributedOn${doseIndex}`" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">{{ poc? Language.EXPIRES_ON : Language.DISTRIBUTED_DATE}}</label>
                <!-- <input v-model="distributedOn" @change="distributedOnChanged" type="date" :name="`distributedOn${doseIndex}`" :id="`distributedOn${doseIndex}`" class="block w-full rounded p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" style='border:none' placeholder="" /> -->
                <date-selection class='ring-indigo-500 ring-1 rounded p-1' :selectedDateChanged="distributedOnChanged" :startDay="distributedOn.getDate()" :startMonthIndex="distributedOn.getMonth()" :startYear="distributedOn.getFullYear()"></date-selection>
            </div>
        </div>
        <div class='grid grid-cols-1 space-x-1 rounded '>
            <div class="align-bottom pt-2">
            <div class="mt-4 relative col-span-1 border border-gray-300 rounded-md px-3 py-4 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600 shadow">
                <label for="site" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Healthcare Professional or Clinic Site</label>
                <input v-model="site" @change="siteChanged" type="text" name="site" id="site" class="block ring-indigo-500 ring-1 w-full rounded  p-1 pt-1 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" style='border:none' placeholder="Site" />
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRefs, watchEffect } from 'vue'
import { 
    Listbox, 
    ListboxButton, 
    //ListboxLabel, 
    ListboxOption, 
    ListboxOptions } from '@headlessui/vue'
import {useStore} from "vuex";
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import ConstantsManager from "./../../../../managers/ConstantsManager.js";
import Language from "./../../../../constants/language/english.js";
import DateSelection from "./../DateSelection.vue"
const mfgs = ['-- Please Select --']
Object.entries(ConstantsManager.appConstants().VACCINE_MANUFACTURERS).map((i) => mfgs.push(i[1]));
console.log(mfgs);
export default {
    setup (props) {
        const store = useStore();
        let mfg = "";
        let _lot = "";
        let _distributed = new Date()
        let _site = "";
        console.log("store.state.dose1Manufacturer", store.state.dose1Manufacturer)
        if(props.doseIndex === 1) {
            mfg = "-- Please Select --";
            _lot = store.state.dose1LotID;
            _distributed = new Date(store.state.dose1DistributedDate);
            _site = store.state.dose1Site;
        } else if(props.doseIndex === 2){
            mfg = "-- Please Select --";
            _lot = store.state.dose2LotID;
            _distributed = new Date(store.state.dose2DistributedDate)
            _site = store.state.dose2Site;
        } else if(props.poc === true){
            mfg = store.state.selectedManufacturer
            _lot = store.state.selectedLotID
            console.log(store.state.expirationDate)
            _distributed =  new Date(store.state.expirationDate);
            _site = store.state.site;
        }
        const selectedManufacturer = ref(mfg)
        const lot = ref(_lot);
       
         const state = reactive({
            lotID: lot.value,
            startDay: null, 
            startMonth: null,
            startYear: null,
            distributedOn: _distributed,
            site: _site
        })
        watchEffect(() => {
            if(props.doseIndex === 1 ){
                if(selectedManufacturer.value !== ""){
                    store.commit( "setDose1Manufacturer", selectedManufacturer.value);  
                }
            } else if(props.doseIndex === 2){
                if(selectedManufacturer.value !== "-- Please Select --" && selectedManufacturer.value !== ""){
                    store.commit( "setDose2Manufacturer", selectedManufacturer.value);  
                }
            } else if(props.poc === true){
                if(selectedManufacturer.value !== "-- Please Select --" && selectedManufacturer.value !== ""){
                    store.commit("setSelectedManufacturer", selectedManufacturer.value)
                    console.log(true)
                }
            }
        })
        return {
            Language,
            selectedManufacturer,
            ...toRefs(state)
            }
    },
    components: {
        Listbox,
        ListboxButton,
        //ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
        DateSelection,
    },
    computed:{
        manufacturers(){
            return mfgs
        }
    },
    methods:{
        lotIDChanged(){
            if(this.doseIndex ===  1 ) {
                this.$store.commit("setDose1LotID", this.lotID);
            } else if(this.doseIndex === 2) {
                this.$store.commit("setDose2LotID", this.lotID);
            } else if(this.poc === true){
                this.$store.commit("setSelectedLotID", this.lotID)
            }
        },
        distributedOnChanged(date){
            console.log("setting date")
            this.distributedOn = date;
             if(this.doseIndex === 1 ) {
                 console.log("Comitting Dose 1 Distributed Date", date);
                this.$store.commit("setDose1DistributedDate", date);
            } else if (this.doseIndex === 2){
                this.$store.commit("setDose2DistributedDate", date);
            }else if (this.poc === true){
                this.$store.commit("setExpirationDate", date);
            }
        },
        siteChanged(){
            if(this.doseIndex ===  1 ) {
                this.$store.commit("setDose1Site", this.site);
            } else if(this.doseIndex === 2) {
                this.$store.commit("setDose2Site", this.site);
            }
        }
    },
    props:["doseIndex", "poc"]
}
</script>

<style  scoped>

</style>