<template>
    <div class="p-2 flex-1">
        <div class='flex text-2xl'>
            <div class="flex-grow flex"> 
                 
                <div class='flex-col'>
                    <div class='text-sm pl-2 '> 
                        <i class="fa fa-syringe text-indigo-600 pt-2"></i>
                        Manufacturer
                        
                    </div>
                    <span class='pl-2 text-lg font-normal text-warm-gray-700'> {{token.mfg}} </span>
                </div>
            </div>
            <div class="flex-grow flex"> 
                <div class='flex-col'>
                    <div class='text-sm pl-2 '>
                        <i class="fas fa-box-open text-indigo-600 pt-2"></i>   
                        Lot ID 
                    </div>
                    <div class='pl-2 text-lg font-normal text-warm-gray-700'>{{token.lot}} </div>
                </div>
            </div>
             <div class='flex-grow flex'> 
                 <div class='flex-col'>
                     <div class='text-sm pl-2 '>
                        <i class="far fa-calendar-alt text-indigo-600 pt-2"></i> Date
                     </div>
                    <div class=' pl-2 text-lg font-normal text-warm-gray-700'> {{displayDate}} </div>
                 </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    methods:{},
    computed:{
        iconClasses(){
            let response = "fa fa-syringe text-2xl";
            if(this.token.distributed){response += " text-indigo-600"}
            else {response += " text-gray-400"}
            return response;
        },
        displayDate(){
            let response = ""
            try{let _nd = new Date(this.token.distributed); response = _nd.toLocaleDateString('en-US')}catch(err){response = this.token.distributed;}
            return response;
        }
    },
    props: ["token"]
}
</script>

<style lang="scss" scoped>

</style>