const {BellIcon} = require("@heroicons/vue/outline")
const HomeIcon = require("@heroicons/vue/outline")
const KeyIcon= require("@heroicons/vue/outline")
const MenuIcon = require("@heroicons/vue/outline")
const UserIcon = require("@heroicons/vue/outline")
let icons = [BellIcon, HomeIcon, KeyIcon, MenuIcon, UserIcon];

const MedicalConstants = require('./VXVaccineConstants.js')
const TestClassLocations = require('./test_VXConstants.js');
const MainClassLocations = require('./main_VXConstants.js');
const TestAccountKeys = require('./test_AccountSampleKeys.js')
const MainAccountKeys = require('./main_AccountSampleKeys.js')
module.exports = {
    patientNavigation: [
        { name: 'Home', href: '#', icon: icons[1] },
        { name: 'History', href: '#', icon: icons[3] },
        { name: 'Profile', href: '#', icon: icons[5] },
        { name: 'Password', href: '#', icon: icons[2] },
        { name: 'Alerts', href: '#', icon: icons[0] },
      ],
      LOGIN_TYPE_ADMIN: "admin",
      LOGIN_TYPE_PATIENT: "patient",
      LOGIN_TYPE_PROVIDER: "provider",
      DEFAULT_PAYER_PATH: "m/0/0/0",
      ADMIN_OWNER_PATH: "m/0/0/1",
      ADMIN_PAYMENT_PATH: "m/0/0/0",
      PROVIDER_OWNER_PATH: "m/0/1/0",
      PROVIDER_PAYMENT_PATH: "m/0/0/0",
      PATIENT_OWNER_PATH: "m/0/1/1",
      PATIENT_PAYMENT_PATH: "m/0/0/0",
      TEST_NETWORK: "test",
      MAIN_NETWORK: "main",
      LIVE_NETWORK: "live",
      //VACCINE CONSTANTS 
      VACCINE_MANUFACTURERS:MedicalConstants.VACCINE_MANUFACTURERS,
      VACCINE_CODES:MedicalConstants.VACCINE_CODES,
      VACCINE_NDC_CODES_1:MedicalConstants.VACCINE_NDC_CODES_1,
      VACCINE_NDC_CODES_2:MedicalConstants.VACCINE_NDC_CODES_2,
      VACCINE_DOSE_INTERVAL:MedicalConstants.VACCINE_DOSE_INTERVAL,
      VACCINE_INTERVAL_DAYS_AVAILABLE:MedicalConstants.VACCINE_INTERVAL_DAYS_AVAILABLE,
      VACCINE_DOSE_TOTAL:MedicalConstants.VACCINE_DOSE_TOTAL,
      VACCINE_ADMIN_CODES_1:MedicalConstants.VACCINE_ADMIN_CODES_1,
      VACCINE_ADMIN_CODES_2:MedicalConstants.VACCINE_ADMIN_CODES_2,
      //TEST NETWORK CLASS BASE LOCATIONS 
      TEST_CREDENTIAL_CLASS_LOCATION : TestClassLocations.CREDENTIAL_CLASS_LOCATION ,
      TEST_CREDENTIAL_CLASS_ORIGIN: TestClassLocations.CREDENTIAL_CLASS_ORIGIN,
      TEST_MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN: TestClassLocations.MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN,
      TEST_MEDICAL_CREDENTIAL_FACTORY_CLASS_LOCATION: TestClassLocations.MEDICAL_CREDENTIAL_FACTORY_CLASS_LOCATION,
      TEST_VACCINATION_RECORD_CLASS_ORIGIN : TestClassLocations.VACCINATION_RECORD_CLASS_ORIGIN,
      TEST_VACCINATION_RECORD_CLASS_LOCATION : TestClassLocations.VACCINATION_RECORD_CLASS_LOCATION,
      //MAIN NETWORK CLASS BASE LOCATIONS 
      MAIN_CREDENTIAL_CLASS_LOCATION : MainClassLocations.CREDENTIAL_CLASS_LOCATION ,
      MAIN_CREDENTIAL_CLASS_ORIGIN: MainClassLocations.CREDENTIAL_CLASS_ORIGIN,
      MAIN_MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN: MainClassLocations.MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN,
      MAIN_MEDICAL_CREDENTIAL_FACTORY_CLASS_LOCATION: MainClassLocations.MEDICAL_CREDENTIAL_FACTORY_CLASS_LOCATION,
      MAIN_VACCINATION_RECORD_CLASS_ORIGIN : MainClassLocations.VACCINATION_RECORD_CLASS_ORIGIN,
      MAIN_VACCINATION_RECORD_CLASS_LOCATION : MainClassLocations.VACCINATION_RECORD_CLASS_LOCATION,
      //TEST NETWORK SAMPLE KEYS 
      TEST_PAYMENT_PRIVATE_KEY: TestAccountKeys.PAYMENT_PRIVATE_KEY,
      TEST_ADMIN_SEED: TestAccountKeys.ADMIN_SEED,
      TEST_ADMIN_PRIVATE_KEY: TestAccountKeys.ADMIN_PRIVATE_KEY,
      TEST_ADMIN_OWNER_PRIVATE_KEY: TestAccountKeys.ADMIN_OWNER_PRIVATE_KEY,
      TEST_PROVIDER_SEED: TestAccountKeys.PROVIDER_SEED,
      TEST_PROVIDER_PRIVATE_KEY: TestAccountKeys.PROVIDER_PRIVATE_KEY,
      TEST_PROVIDER_PAYMENT_PRIVATE_KEY: TestAccountKeys.PROVIDER_PAYMENT_PRIVATE_KEY,
      TEST_PATIENT_SEED: TestAccountKeys.PATIENT_SEED,
      TEST_PATIENT_PRIVATE_KEY: TestAccountKeys.PATIENT_PRIVATE_KEY,
      TEST_PATIENT_PAYMENT_PRIVATE_KEY: TestAccountKeys.PATIENT_PAYMENT_PRIVATE_KEY,
      //MAIN NETWORK SAMPLE KEYS 
      MAIN_PAYMENT_PRIVATE_KEY: MainAccountKeys.PAYMENT_PRIVATE_KEY,
      MAIN_ADMIN_SEED: MainAccountKeys.ADMIN_SEED,
      MAIN_ADMIN_PRIVATE_KEY: MainAccountKeys.ADMIN_PRIVATE_KEY,
      MAIN_ADMIN_OWNER_PRIVATE_KEY: MainAccountKeys.ADMIN_OWNER_PRIVATE_KEY,
      MAIN_PROVIDER_SEED: MainAccountKeys.PROVIDER_SEED,
      MAIN_PROVIDER_PRIVATE_KEY: MainAccountKeys.PROVIDER_PRIVATE_KEY,
      MAIN_PROVIDER_PAYMENT_PRIVATE_KEY: MainAccountKeys.PROVIDER_PAYMENT_PRIVATE_KEY,
      MAIN_PATIENT_SEED: MainAccountKeys.PATIENT_SEED,
      MAIN_PATIENT_PRIVATE_KEY: MainAccountKeys.PATIENT_PRIVATE_KEY,
      MAIN_PATIENT_PAYMENT_PRIVATE_KEY: MainAccountKeys.PATIENT_PAYMENT_PRIVATE_KEY,

}