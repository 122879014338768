<template>
    <div>
        <LoggedIn >
            <template v-slot:welcomePanel>
            <div class="rounded-lg bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">My Keys</h2>
                <div class="bg-white p-6">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <div class="sm:flex sm:space-x-5">
                    <div class="flex-shrink-0">
                        <img class="mx-auto h-20 w-20 rounded-full" src="./../../../assets/images/VXPASS-Provider-Circle-Logo.png" alt="" />
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p class="text-sm font-medium text-gray-600">These Are Your Account Keys</p>
                        <p class="text-xl font-bold text-gray-900 sm:text-2xl">Account Info</p>
                        {{paymentAddress}}
                        <p class="text-sm font-medium text-gray-600"></p>
                    </div>
                    </div>
                    <div class="mt-5 grid grid-cols-3 justify-center sm:mt-0">
                       
                    </div>
                </div>
                </div>
            </div>
        </template>
        <template v-slot:actionsPanel>
            <three-tier-with-callout class='bg-gray-900'></three-tier-with-callout>
        </template>
        <template v-slot:rightColumn >
            <div class='bg-white rounded-xl shadow'>
             <div class="grid grid-cols-5  p-2 m-2 rounded  shadow-xl items-center ring-1 ring-indigo-200">
                            <div class='col-span-1'>{{Language.SEED}}</div>
                            <div class='col-span-3'><span class='text-xs max-w-sm '> {{seed}} </span></div>
                            <div class='col-span-1 flex-col'>
                                <button @click="showSeed = !showSeed" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-50 bg-pink-500 hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                                    {{showSeed 
                                        ? Language.HIDE  
                                        : Language.SHOW}}
                                </button>
                                <button @click="copy('seed')" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 hover:bg-indigo-800">
                                    {{Language.COPY }}
                                </button>
                            </div>
            </div>
            <div class='grid grid-cols-5  p-2 m-2 rounded  shadow-xl  items-center ring-1 ring-indigo-200'>
                <div class='col-span-1'>{{Language.PRIVATE_KEY}}</div>
                <div class='col-span-3 '>
                    <span class='text-xs max-w-sm break-all'> {{privateKey}} </span>
                    </div>
                <div class='col-span-1 flex-col'>
                    <button @click="showPrivateKey = !showPrivateKey" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-indigo-300 rounded-md shadow-sm text-sm font-medium text-gray-50 bg-pink-500 hover:bg-red-700 focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                        {{showPrivateKey 
                            ? Language.HIDE  
                            : Language.SHOW}}
                    </button>
                    <button @click="copy('pk')" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 hover:bg-indigo-800">
                        {{Language.COPY }}
                    </button>
                </div>
            </div> 
            <div class='grid grid-cols-5 p-2 m-2 rounded shadow-xl  items-center ring-1 ring-indigo-200'>
                <div class='col-span-1'>{{Language.ADDRESS}}</div>
                <div class='col-span-3 '>
                    <span class='text-xs max-w-sm break-all'> {{address}} </span>
                </div>
                <div class='col-span-1 flex-col'>
                    <button @click="showAddress = !showAddress" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-indigo-300 rounded-md shadow-sm text-sm font-medium text-gray-50 bg-pink-500 hover:bg-red-700 focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500">
                        {{showAddress 
                            ? Language.HIDE  
                            : Language.SHOW}}
                    </button>
                    <button @click="copy('address')" type="button" class="m-1 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 hover:bg-indigo-800">
                        {{Language.COPY }}
                    </button>
                </div>
            </div>
            </div>
            <input type="hidden" id="copy-input">
        </template>
        </LoggedIn>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Run from "run-sdk";
import LoggedIn from "./layouts/LoggedIn.vue"
import Language from "./../../../constants/language/english.js"
import ThreeTierWithCallout from '../../../components/tailwind/pricing/ThreeTierWithCallout.vue'
import ConstantsManager from '../../../managers/ConstantsManager'
export default {
    components:{LoggedIn, ThreeTierWithCallout},
    setup () {
        const state = reactive({
            showPrivateKey: false,
            showSeed: false,
            showAddress: false,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    methods:{
         copy(_type){
            let cpy = "";
             let _toCopy = document.querySelector('#copy-input')
             _toCopy.setAttribute('type', 'text')
            if(_type === 'all'){
                cpy = `Seed: ${this.$store.state.providerSeed} 
                Private Key: ${this.$store.state.providerPrivKey}
                Address: ${this.$store.state.providerAddress}`
            }else if(_type ==="seed"){cpy = this.$store.state.providerSeed}
            else if(_type ==="pk"){cpy = this.$store.state.providerPrivKey}
            else if(_type ==="address"){cpy = this.$store.state.providerAddress}
            console.log({cpy})
             _toCopy.setAttribute('type', 'text')
             _toCopy.value = cpy;
            _toCopy.select();
            try {
                document.execCommand('copy');
                 _toCopy.setAttribute('type', 'hidden')
          } catch (err) {
                alert('Oops, unable to copy');
          }
        }
    },
    computed:{
        privateKey(){
            if(this.showPrivateKey){return this.$store.state.providerPrivKey}
            else {return "*** " + Language.HIDDEN + "***"}
        },
        seed(){
            if(this.showSeed){return this.$store.state.providerSeed}
            else{return "*** " + Language.HIDDEN + " ***"}
        },
        address(){
             if(this.showAddress){return this.$store.state.providerAddress}
            else{return "*** " + Language.HIDDEN + " ***"}
        },
        paymentAddress(){
            let _net  = this.$store.state.network;
            let _run = new Run({network: _net, purse:ConstantsManager.paymentPrivateKey(_net)})
             console.log(_run.purse.address);
            return  _run.purse.address
        },
        firstSix(){
            if(this.$store.state.patientSeed !== ""){
                let wordarray = this.$store.state.patientSeed.split(" ");
                let six = wordarray.slice(0, 6)
                console.log(six)
                let returner = "";
                six.forEach(i => returner = returner + i + "%20");
                return returner;
            }
            return ""
        },
        lastSix(){
            if(this.$store.state.patientSeed !== ""){
                let wordarray = this.$store.state.patientSeed.split(" ");
                let six = wordarray.slice(6, 12)
                console.log(six)
                let returner = "";
                six.forEach(i => returner = returner + i + "%20");
                return returner;
            }
            return ""
        }
    }
}
</script>

<style lang="scss" scoped>

</style>