<template>
    <div>
        <LoggedInWide >
            <template v-slot:welcomePanel >
                <div class="rounded-xl bg-white shadow">
                    <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                    <div class="rounded-xl bg-white p-2">
                        <div class="grid grid-cols-4 items-center justify-items-center">
                            <div class="flex-shrink-0 h-full flex-grow col-span-1 grid items-center items-justify-center">
                                <img class="mx-auto h-24 w-24  rounded-full" src="./../../../assets/images/VXPASS-Provider-Circle-Logo.png" alt="" />
                            </div>
                            <div v-if="!store.state.virtualMode" class="mt-4 col-span-2 text-center flex-grow">
                              <VXQRScanner v-if="!store.state.virtualMode" :respond="respondToScan"/>
                            </div>
                            <div :class="[store.state.virtualMode? 'col-span-3 text-right w-full' : 'col-span-1 text-left ', 'mt-5 flex-1 sm:mt-0  ']">
                                <p v-if="!store.state.virtualMode" class='text-2xl font-normal break-normal'>
                                    {{Language.SCAN_QR_CODE}}
                                </p>
                                <div class=" flexw-full items-right">
                                    <div >
                                        <virtual-mode :virtualMode="store.state.virtualMode"></virtual-mode>
                                        <button @click="createPatient" v-if="store.state.virtualMode" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            New Patient
                                            <PlusIcon class="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 ">
                        <div class="px-6 py-1 text-sm font-medium text-center">
                           <div class="grid grid-cols-2 space-x-1">
                               <div class="col-span-1">
                                   <StatusMini doseType="first" :status="firstDoseStatus" /> 
                                   <FormMini :doseIndex="1" id="dose1Form" />
                               </div>
                               <div class="col-span-1">
                                   <StatusMini doseType="second" :status="secondDoseStatus" /> 
                                   <FormMini :doseIndex="2" id="dose2Form" />
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actionsPanel >
                <div class="grid grid-cols-1 sm:grid-cols-2 bg-white rounded shadow">
                    <div class='col-span-1 '>
                        
                        <PatientDetailsAndForm :patientDetails="patientDetails" :edit="editPatientDetails" :savePatientInfo="savePatientInfo" :close="hidePatientEdit" :showEditFunction="showPatientEdit"/>
                    </div>
                    <div class='col-span-1 p-3 m-3 ml-2 pb-10 mb-10'>
                        <div class="m-2 p-2">
                            <div class='text-left'> 
                            <label> Redemption Code </label>
                            <div class='flex'>
                            <input class='rounded-md ring-1 ring-indigo-600 pl-6 w-full ' v-model="redemptionCode" /> 
                            
                            <div>
                                <button @click="checkCode" class="inline-flex ml-1 text-xs items-center p-1.5 border border-transparent rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"> 
                                    Check Code 
                                </button> 
                            </div> 
                            <div v-if='canRedeem' class='p-2 text-2xl text-green-600'> <i class='fa fa-check '></i> </div>
                            <div v-else  class='p-2 text-2xl text-red-500'> <i class='fa fa-close'></i> </div>
                            </div> 
                            </div>
                        </div>
                        <div class="m-2 p-2">
                            <VerticalTimeline :steps="steps" :canSend="showSendButton" :sendFunction="sendButtonClick"/>
                        </div>
                    </div>
                    <button @click="clearPatient" class='bg-red-500 text-white rounded-xl text-md max-w-sm p-2 m-2'> <i class="fas fa-redo-alt"></i> Clear All </button>
                </div>
            </template>
            <template v-slot:footer>
                <!-- <two-button-wide-modal :open="showEmailModal" v-on:modal-confirmed="emailConfirmClicked" v-on:modal-canceled="cancelClicked" title="" message="Email this code to the patient" confirmText="Next >"> 
                    <template v-slot:content >
                        <div class="flex-1">
                            <div >
                                <button class="bg-green-500 text-white p-2 m-2 rounded-xl shadow focus:ring-2 focus:ring-green-600" @click="copy('seed')">{{copyText}}</button>
                            </div>
                            <div class="">{{store.state.patientSeed}}</div>
                        </div>
                        <div class="p-2">
                            <input class="m-2" type="checkbox"  v-model="confirmEmailSent"> 
                            <span class='text-gray-600'>I have sent the patient their login code</span>
                        </div>
                        <div v-if="!confirmEmailSent" class="text-red-500">
                            {{emailNotSentWarning ? "You must send the patient their login code before moving forward." : ""}}
                        </div>
                        <div class="">
                            <input type="hidden"  id="copy-input" style="white-space: pre-line;">
                        </div>
                    </template>
                </two-button-wide-modal> -->

                <provider-confirm-details :open="showModal" v-on:modal-confirmed="confirmClicked" v-on:modal-canceled="cancelClicked" title="" message="The details of this confirmation can not be changed. Please double check that all the information is complete." confirmText="Send"></provider-confirm-details>
            </template>
        </LoggedInWide>
    </div>
</template>

<script>
import {ref, inject, reactive, toRefs } from 'vue'
import {useStore, mapState} from 'vuex';
import {PlusIcon} from '@heroicons/vue/outline'
import LoggedInWide from "./layouts/LoggedInWide.vue" 
import VXQRScanner from './../../../components/apps/common/VXQRScanner.vue'
import StatusMini from "./../../../components/apps/common/dose/StatusMini.vue"
import FormMini from "./../../../components/apps/common/dose/FormMini.vue"
import PatientDetailsAndForm from "./../../../components/apps/common/PatientDetailsAndForm.vue"
import VerticalTimeline from "./../../../components/apps/provider/VerticalTimeline.vue"
import Run from "run-sdk"
import Utilities from './../../../helpers/Utilities.js';
import ConstantsManager from "./../../../managers/ConstantsManager.js";
import VXTransactionHelper from "./../../../helpers/VXTransactionHelper.js";
//import TwoButtonWideModal from "./../../../components/tailwind/modals/TwoButtonWide.vue"
//import DoseDetailsMini from '../../../components/apps/provider/DoseDetailsMini.vue';
import VirtualMode from '../../../components/apps/provider/VirtualMode.vue';
import EncryptionHelper from './../../../helpers/EncryptionHelper.js';
import CodeManager from './../../../managers/CodeManager.js'
import ProviderConfirmDetails from "./../../../components/apps/provider/modals/ProviderConfirmDetails.vue"
export default {
    components: {LoggedInWide, VXQRScanner, StatusMini, FormMini, PatientDetailsAndForm, VerticalTimeline, 
    //TwoButtonWideModal, 
    VirtualMode, PlusIcon, ProviderConfirmDetails},
    async setup () {
        const store = useStore();
        let Language = inject("Language");
        let patientDetails = ref({})
        console.log("Provider Private Key", store.state.providerPrivKey);
        let _rc = "";
        if(store.state.patientRedeemCode !== ""){
            _rc = store.state.patientRedeemCode;
        }
        let showSendButton = ref(false);
        const state = reactive({
            count: 0,
            emptyFields: [],
            editPatientDetails: true,
            displayUpdate: false,
            showModal: false,
            showEmailModal: false,
            confirmEmailSent: false,
            copied:false,
            emailNotSentWarning: false,
            redemptionCode: _rc,
            canRedeem: false,
        })
    
        return {
            store,
            showSendButton,
            Language,
            patientDetails,
            ConstantsManager,
            ...toRefs(state),
        }
    },
    async mounted(){
        console.log("Selected Credential:", this.selectedCredential);
        if(!this.selectedCredential){this.$router.push('/apps/provider/profile')}
        if(this.$store.state.patientRedeemCode !== ""){
            await this.checkCode(this.$store.state.patientRedeemCode);
        }
        // let response = await CodeManager.burnCode('dokI4l', this.$store.state.providerAddress);
        // console.log('Axios Response From Code Manager:', response);
        // if(response.burned === true){
        //     alert("Burned");
        // }

    },
    methods:{
        async savePatientInfo(details){
            console.log("Details to update:", details)
            await this.$store.dispatch("updatePatientDetails", details);
            this.editPatientDetails = false;
            let _doseComplete = this.doseInfoComplete;
            console.log("Dose Complete Status before save Patient Info", _doseComplete)
            if(this.patientInfoComplete === 'complete' && _doseComplete){
                this.showSendButton = true;
            } else {
                this.showSendButton = false;
            }
        },
        checkPatientInfoComplete(){
            let complete = true; 
            let response = [];
            console.log(this.$store.state.patientBirthday);
            if(this.$store.state.patientAddress === ""){complete = false; response.push("Address")}
            if(this.$store.state.patientFirstName === ""){complete = false; response.push("First Name")}
            //if(this.$store.state.patientMiddleInitial === ""){complete = false; response.push("Middle Initial")}
            if(this.$store.state.patientLastName === ""){complete = false; response.push("Last Name")}
            if(this.$store.state.patientBirthday === null || new Date(this.$store.state.patientBirthday).getTime() === "NaN"){complete = false; response.push("Birthday")}
            // if(this.$store.state.patientStateID === ""){complete = false; response.push("State ID")}
            // if(this.$store.state.patientPassportNumber === ""){complete = false; response.push("Passport Number")}
            this.emptyFields = response;
            console.log("Empty Fields", this.emptyFields)
            if(response.length > 0){this.showSendButton = false; return response}
            return complete;
        },
        showPatientEdit(){
            this.patientDetials = this.fetchPatientDetails();
            console.log(this.patientDetials );
            this.editPatientDetails = true;
            this.showSendButton = false;
        },
        hidePatientEdit(){
            this.editPatientDetails = false;
            this.checkPatientInfoComplete();
        },
        async respondToScan(data){
            console.log("Scanned from Control Writing from Page: ", data)
            let input = {};
            let _input = JSON.parse(data);
            input.firstName = _input['firstName'];
            input.middleInitial = _input['middleInitial'];
            input.lastName = _input['lastName'];
            input.birthday = _input['birthday'];
            input.address = _input['address'];
            input.redeemCode = _input['redeemCode'];
            console.log("Input before code Check:", input);
            console.log(await this.$store.dispatch("updatePatientDetails", _input)) 
            this.showPatientEdit = !this.showPatientEdit;
            this.redemptionCode = _input['redeemCode'];
            await this.checkCode()

            location.reload(); 
        },
        sendButtonClick(){
           console.log("send button clicked", this.$store.state.unsentTransactions);
           //TODO: VALIDATE THE DOSE DATA BEFORE SHOWING MODAL.
           //IN THE VALIDATION CHECK IF THE REQUIRED DOSES IS 1 OR 2 
            this.showModal = true;
        //    if(this.$store.state.virtualMode){
        //        this.showEmailModal = true
        //    }else {
              
        //    }
        },
        emailConfirmClicked(){
            if(this.confirmEmailSent){
                this.showEmailModal = false;
                this.showModal = true;
            } else {
                this.emailNotSentWarning = true;
            }
        },
        async confirmClicked(){
            
            let options1 = null;
            let options2 = null;
            let _to = this.$store.state.patientAddress;
            let _network = this.$store.state.network;
            let _run = new Run({network:_network, trust: "*", purse: ConstantsManager.paymentPrivateKey(_network), owner: this.$store.state.providerPrivKey});
            try{
                 await _run.activate();
                await _run.sync();
            }
            catch(err){
                console.log("Error Syncing Run", err); 
                 if(err.message.includes('timeout') || err.message.includes('fetch')){
                    alert('timeout error');
                 }
            }
            let _ndc = ConstantsManager.ndcCode(1, this.$store.state.dose1Manufacturer);
            console.log("NDC:", _ndc);
            if(_ndc === "" || this.$store.state.dose1LotID === ""){
                alert('There is missing vacciantion information') 
                return;
            }
            
            let d1 = new Date(this.dose1DistributedDate).toLocaleDateString('en-US')
            let d1_unchanged = this.dose1DistributedDate
            let d2 = new Date(this.dose2DistributedDate).toLocaleDateString('en-US')
            if(d1_unchanged.year < 2020){
                alert('There must be information for at least 1 complete dose. ') 
                return;
            }
            console.log("DD1:", d1)
            console.log("DD1_1:", d1_unchanged)
            console.log("DD2:", d2)
            options1 = {to: _to, manufacturer: this.$store.state.dose1Manufacturer, ndc: _ndc, lot: this.$store.state.dose1LotID, expiry: "", distributed: d1, site: this.$store.state.dose1Site, firstName: this.$store.state.patientFirstName.trim(), mi: this.$store.state.patientMiddleInitial.trim(), lastName: this.$store.state.patientLastName.trim(), birthday: this.$store.state.patientBirthday}
            console.log({options1})
            options2 = {to: _to, manufacturer: this.$store.state.dose2Manufacturer, ndc: _ndc, lot: this.$store.state.dose2LotID, expiry: "", distributed: d2, site:this.$store.state.dose2Site, firstName: this.$store.state.patientFirstName.trim(), mi: this.$store.state.patientMiddleInitial.trim(), lastName: this.$store.state.patientLastName.trim(), birthday: this.$store.state.patientBirthday}
            console.log({options2})
            let selectedCredential = null; 
            let _class; 
            let _tx;
            try{
                selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
                _class = await _run.load(ConstantsManager.vaccinationRecordClassLocation(_network));
                 _class.sync()
               
            }catch(err){
                console.log(err);
                return;
            }

            try{
                if(this.$store.state.patientRedeemCode === ""){
                    "This redeem code is not valid you will not be paid for this record"
                }
                _tx = VXTransactionHelper.setupVXRecordTransaction(_run, selectedCredential, _class, _to, options1);
                console.log("This is a transaction", _tx);
                _ndc = ConstantsManager.ndcCode(2, this.$store.state.dose2Manufacturer);
                console.log("THis is MFG 2 before packing up a tx", this.$store.state.dose2Manufacturer);
                if(this.$store.state.dose2Manufacturer !== "" && this.$store.state.dose2Manufacturer !== "-- Please Select --"){
                    //Begin Dose 2 
                    //TODO: Make sure the second dose is required && the info is complete before adding the transaction. 
                    _tx =  VXTransactionHelper.addRecordToTransaction(_run, _tx, selectedCredential, _class, _to, options2);
                    console.log("This is a tx2", _tx);
                }
            }catch(err){
                console.log(err)
                return;
            }
            
            try{
                let result =  await _tx.publish();
                if(result){console.log(result);}
                alert(this.Language.SAVE_RECORD_SUCCESS_MESSAGE);
                await this.$store.dispatch("clearPatientPersonalData");
                await this.$store.dispatch("clearPatientDoseData");
                this.showModal = false;
                this.$router.go();
            }catch(err){
                if(err.message.includes('timeout') || err.message.includes('fetch')){
                    alert('There is an issue with the network. This record has been encrypted and stored so you can try again later.');
                    //Do this first before awaiting change so the next one has a new timestamp
                    options1.lastAttempt = new Date().toLocaleDateString('en-US');
                    let crypt_options1 = EncryptionHelper.encryptOptions(this.$store.state.providerPrivKey, options1)
                    let decrypted = EncryptionHelper.decryptOptions(this.$store.state.providerPrivKey, crypt_options1);
                    console.log(decrypted)
                    await this.$store.commit("addUnsentTransaction", crypt_options1)
                    //Do this after awaiting change so the options2 one has a new timestamp
                    options2.lastAttempt = new Date().toLocaleDateString('en-US');
                    let crypt_options2 = EncryptionHelper.encryptOptions(this.$store.state.providerPrivKey, options2)
                    await this.$store.commit("addUnsentTransaction", crypt_options2)
                    return;
                }
                console.log("The Error message is:", err.message); alert(err)
            }
        },
        cancelClicked(){
            console.log("cancel clicked");
            this.showModal = false;
            this.showNewModal = false;
            this.showEmailModal = false;
        },
        async clearPatient(){
            await this.$store.dispatch("clearPatientPersonalData");
            await this.$store.dispatch("clearPatientDoseData");
            await this.$store.commit( "setDose1Manufacturer", "");
            this.$router.go();

        },
        doseDetails(_isDate, index){
            if(_isDate){
                console.log("Date @ DOSE DETAILS:", this.$store.state.dose1DistributedDate);
                if(this.dose2Manufacturer === "-- Please Select -- ") {return}
                if(index === 1){
                    return `Distributed: ${new Date(this.$store.state.dose1DistributedDate).toLocaleDateString('en-US')}`
                }else{ 
                    return `Distributed: ${new Date(this.$store.state.dose2DistributedDate).toLocaleDateString('en-US')}`
                }
            }else{
                if(index === 1){
                    return `lot#: ${this.$store.state.dose1LotID} `
                }else{ 
                    return `lot#: ${this.$store.state.dose2LotID} `
                }
            }
            
        },
        async createPatient(){
            await this.$store.dispatch("clearPatientPersonalData");
            let _patient = Utilities.getRandomAccount();
            let _seed = _patient.seed;
            let _loginType= ConstantsManager.appConstants().LOGIN_TYPE_PATIENT
            await this.$store.dispatch("login", {_seed, _loginType});
            console.log(this.$store.state.patientAddress);
            this.editPatientDetails = true;
            console.log(_patient)
            this.$router.go()
        },
        fetchPatientDetails(){
            let _response = {
                address: this.patientAddress,
                firstName: this.patientFirstName,
                middlename: this.patientMiddleInitial,
                lastName: this.patientLastName,
                birthday: this.patientBirthday
            }
            console.log("Patient Details", _response);
            return _response; 
        },
        copy(_type){
            let cpy = "";
             let _toCopy = document.querySelector('#copy-input')
             _toCopy.setAttribute('type', 'text')
            if(_type === 'all'){
                cpy = `Seed: ${this.$store.state.patientSeed} 
                Private Key: ${this.$store.state.patientPrivKey}
                Address: ${this.$store.state.patientAddress}`
            }else if(_type ==="seed"){
                 cpy = `Thank you for choosing ${this.store.state.practiceName} & VXPASS. We have sent your records to an account weve created for you. Your VXPASS Login Code is : ${this.$store.state.patientSeed} `

            }
            else if(_type ==="pk"){cpy = this.$store.state.patientPrivKey}
            else if(_type ==="address"){cpy = this.$store.state.patientAddress}
            console.log({cpy})
             _toCopy.setAttribute('type', 'text')
             _toCopy.value = cpy;
            _toCopy.select();
            try {
                document.execCommand('copy');
                 _toCopy.setAttribute('type', 'hidden')
          } catch (err) {
                alert('Oops, unable to copy');
          }
          this.copied = true;
        },
        async checkCode(){
            let response;
            try{
                response = await CodeManager.checkCode(this.redemptionCode);
                console.log(response);
                if(response.burned){
                    alert('this code has already been used. you will not be paid for this record.')
                }else{
                    this.canRedeem = true;
                }
            }catch(err){
                alert('Code is no good. You will not be paid on this.')
                response = err
            }
            return response;
        },
        async burnCode(){
            let response;
            try{
                response = await CodeManager.checkCode(this.redemptionCode);
                console.log(response);
            }catch(err){
                response = err
            }
            return response;
        },
    },
    computed:{
        doseInfoComplete(){
            console.log("Checking Doese Info Complete:", this.$store.state.dose1Manufacturer )
            if(this.$store.state.dose1Manufacturer!== "-- Please Select --" 
                && this.$store.state.dose1Manufacturer !== ""){
                    if(this.$store.state.dose1LotID !== "" ){
                        if(this.$store.state.dose1DistributedDate !== "" ){
                            if(this.$store.state.dose1Site !== ""){
                                return true;
                            }
                        }
                    }
            }
            return false;
        },
        copyText(){
            if(this.copied){return "√ COPIED"}
            return this.Language.COPY;
        },
        firstDoseStatus(){
            return "staged"
        },

        secondDoseStatus(){
            return "staged"
        },

        steps(){
            let doseStep;
            let patientInfoStep;
            if(this.doseInfoComplete){
                doseStep = { name: 'Dose Info', description: 'All Information about patient doses must be complete.', href: '#', status: 'complete' }
            }else {
                doseStep = { name: 'Dose Info', description: 'All Information about patient doses must be complete.', href: '#', status: 'current' }
            }
            let piComplete = this.patientInfoComplete;
            console.log("Is pi Complete at steps?",  piComplete)
            if(this.patientInfoComplete === "complete"){
                patientInfoStep = {
                            name: 'Profile information',
                            description: `Patient Infomation is ${this.patientInfoComplete}`,
                            errors: this.emptyFields,
                            href: '#',
                            status: 'complete',
                        }
            } else {
                 patientInfoStep = {
                            name: 'Profile information',
                            description: `Patient Infomation is ${this.patientInfoComplete}`,
                            errors: this.emptyFields,
                            href: '#',
                            status: 'current',
                }
            }
            return [
                        doseStep, 
                        patientInfoStep,
                        { name: `
                        



                        
                        `, description: '', href: '#', status: 'button' },
                        ]
        },

        patientInfoComplete(){
            let response = this.checkPatientInfoComplete()
            if(response === true)
                return "complete"
            else{
                return `incomplete.
                `; 
            }    
        },

        formattedEmptyFields(){
            if(this.emptyFields.length === 0 ) return; 
            let response = this.emptyFields.forEach((i) => 
            `${i}
`);
            return response;
        },

        modalBirthday(){
            let response = "";
            try{
                response = this.$store.state.patientBirthday.toLocaleDateString("en-US");
                console.log('Modal Birthday Success')
            }catch(err){   
                try{
                    response = new Date(this.$store.state.patientBirthday).toLocaleDateString("en-US");
                    console.log('Modal Birthday Fail', this.$store.state.patientBirthday,  'New Response:',   response)
                }catch(err){
                    response = this.$store.state.patientBirthday;
                    console.log('Modal Birthday Reset')
                }
               
            }
            return response;
        },
        
        ...mapState(['selectedCredential','dose1Manufacturer', 'dose2Manufacturer', 'dose1DistributedDate', 'dose2DistributedDate', 'patientAddress', 'patientFirstName', 'patientMiddleInitial', 'patientLastName', 'patientBirthday', 'patientStateID', 'patientPassportNumber'])
    }
}
</script>

<style scoped>

</style>