<template>
<div>
    <lite-menu v-on:buy-clicked="submit"></lite-menu>
    <Hero v-on:clicked="submit"></Hero>
    <features></features>
    <CTA></CTA>
    <CTA2 v-on:clicked="submit"></CTA2>
    <Footer></Footer>
    <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="v => loading = v"
        />
   </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import LiteMenu from "./../../../components/apps/lite/LiteMenu.vue"
import Hero from './../../../components/apps/lite/home/Hero.vue';
import Features from './../../../components/apps/lite/home/Features.vue';
import CTA from './../../../components/apps/lite/home/CTA.vue';
import CTA2 from './../../../components/apps/lite/home/CTA2.vue';
import Footer from '../../../components/apps/lite/Footer.vue';


export default {
    components:{
        StripeCheckout,
        LiteMenu,
        Hero,
        Features,
        CTA,
        CTA2,
        Footer,
    },
    setup () {
        const showModal = ref(false);
         const lineItems =  [{price: 'price_1JL7nWA5eISFnysutNevszAn', quantity: 1}]

        let _baseUrl = window.location.origin;
        console.log(_baseUrl)

        const state = reactive({
          loading: false,
          publishableKey: "pk_test_51ITASLA5eISFnysulKCtJ3cB1hGeqzNUdbXnhjkINb2hd9Iew6GvG4888dVOCpHOx6sdjipfbk0QW9xzTIxiCoCk00PdFWNOi2",
          successURL: `${_baseUrl}/apps/lite/payment-success`,
          cancelURL: `${_baseUrl}/payment-fail`,
        })
            
        return {
            showModal,
            lineItems,
            ...toRefs(state),
        }
    },
    methods:{
         async submit () {
            await this.$refs.checkoutRef.redirectToCheckout();
        },
    }
}
</script>

<style lang="scss" scoped>

</style>