<template>
<div class='flex justify-center'>
    <div> <i class="text-xl fas fa-id-card"></i></div>
    <div class='pl-2 pt-1 flex-grow text-left text-xs text-warm-gray-60 break-all'>{{store.state.patientAddress}}</div>
</div>

<div class='grid grid-cols-2 space-x-4 px-10 pt-10 text-left'>
    <div class='col-span-1'>
        <div class='avatar pl-3'><img class='h-10 w-10' src='./../../../../assets/images/radial-logo.png'> </div>
    </div>
    <div class='col-span-1'>
        <div class='flex-grow text-left text-2xl text-warm-gray-60'>{{store.state.selectedManufacturer}}</div>
    </div>
    <div class='col-span-1'>
        <div class=''>Lot ID</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-grow text-left  text-warm-gray-60'>{{store.state.selectedLotID}}</div>
    </div>
    <div class='col-span-1'>
        <div class='break-all'>Name</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-1 sm:flex'>
            <div class=''>{{store.state.patientFirstName}}</div>
            <div class=''>{{store.state.patientMiddleInitial}}</div>
            <div class=''>{{store.state.patientLastName}}</div>
        </div>
    </div>
    <div class='col-span-1'>
        <div class='break-all'>Birthday</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-1 sm:flex'>
            <div class=''>{{store.state.patientBirthday }}</div>
        </div>
    </div>
     <div class='col-span-1'>
        <div class='break-all'>Race</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-1 sm:flex'>
            <div class=''>{{store.state.patientRace }}</div>
        </div>
    </div>
    <div class='col-span-1'>
        <div class='break-all'>Gender</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-1 sm:flex'>
            <div class=''>{{store.state.patientGender }}</div>
        </div>
    </div>
    <div class='col-span-1'>
        <div class='break-all text-sm'>Local ID #</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-1 sm:flex'>
            <div class=''>{{store.state.stateID}}</div>
        </div>
    </div>
    <div class='col-span-1'>
        <div class='break-all text-sm'>National ID #</div>
    </div>
    <div class='col-span-1'>
        <div class='flex-1 sm:flex'>
            <div class=''>{{store.state.passportNumber}}</div>
        </div>
    </div>
</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import {useStore} from "vuex";

export default {
    setup () {
        const store = useStore();
        const state = reactive({
            count: 0,
        })
    
        return {
            store,
            ...toRefs(state),
        }
    }
}
</script>

<style lang="scss" scoped>

</style>