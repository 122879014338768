module.exports = {
    VACCINE_MANUFACTURERS:{Pfizer:"Pfizer", Moderna: "Moderna", AstraZenica: "Astra Zenica", Janssen:"Janssen", Novovax:"Novovax"},
    VACCINE_CODES:{Pfizer:"91300", Moderna: "91301", AstraZenica: "91302", Janssen:"91303", Novovax:"91304"},
    VACCINE_NDC_CODES_1:{Pfizer:"59267-1000-1", Moderna: "80777-273-10", AstraZenica: "0310-1222-10", Janssen:"59676-580-05", Novovax:"80631-100-01"},
    VACCINE_NDC_CODES_2:{Pfizer:"59267-1000-01", Moderna: "80777-0273-10", AstraZenica: "00310-1222-10", Janssen:"59676-0580-05", Novovax:"80631-1000-01"},
    VACCINE_DOSE_INTERVAL:{Pfizer:"21", Moderna: "28", AstraZenica: "28", Janssen:"0", Novovax:"28"},
    VACCINE_INTERVAL_DAYS_AVAILABLE:{Pfizer:"14", Moderna: "14", AstraZenica: "14", Janssen:"0", Novovax:"14"},
    VACCINE_DOSE_TOTAL:{Pfizer:"2", Moderna: "2", AstraZenica: "2", Janssen:"1", Novovax:"28"},
    VACCINE_ADMIN_CODES_1:{Pfizer:"0001A", Moderna: "0011A", AstraZenica: "0021A", Janssen:"0031A", Novovax:"0041A"},
    VACCINE_ADMIN_CODES_2:{Pfizer:"0002A", Moderna: "0012A", AstraZenica: "0022A", Novovax:"0042A"},
    }