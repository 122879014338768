<template>
<div class='flex space-x-1'>
    <div>
        <label for="month" class="block text-xs font-medium ">Month</label>
        <select @change="selectedMonthChanged" id="month" name="month" v-model="month" class="text-gray-600 mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        <option v-for="m in months" :key="m">{{m}}</option>
        </select>
    </div>
    <div>
        <label for="day" class="block text-xs font-medium ">Day</label>
        <select @change="selectedDayChanged" id="day" name="day" v-model="day" class="text-gray-600 mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        <option v-for="d in days" :key="d">{{d}}</option>
        </select>
    </div>
    <div class='flex-1'>
        <label for="year" class="block text-xs font-medium pb-1">Year</label>
         <input @change="selectedYearChanged" v-model="year" type="number" id='year' name="year" placeholder="1980" class='text-gray-600 w-full  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'  />
    </div>

</div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
const days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
export default {
    setup (props) {
        console.log("Props at date selector", props)
        let _sy =  ref(props.startYear);
        if(_sy.value < 1900){_sy.value = 1980}
        let _sd = ref(props.startDay)
        let _smi = ref(props.startMonthIndex)
        let _sm = ref()
        if(_smi.value){
            _sm.value = months[_smi.value];
            console.log(_sm.value)
        }
        
        const state = reactive({
            day: parseInt(_sd.value),
            monthIndex: _smi.value,
            year: _sy.value,
            month:_sm.value,
        })
    
        return {
            days,
            months,
            ...toRefs(state),
        }
    },
    methods:{
        selectedMonthChanged(){
            this.$emit('selected-month-changed');
            this.monthIndex = this.months.indexOf(this.month)
            console.log(this.monthIndex)
            if(this.selectedDate){
                console.log(this.selectedDate)
            }
        },
        selectedDayChanged(){
            this.$emit('selected-day-changed');
            console.log(this.day);
             if(this.selectedDate){
                console.log(this.selectedDate)
            }
        },
        selectedYearChanged(){
            this.$emit('selected-year-changed');
            console.log(this.year);
             if(this.selectedDate){
                console.log(this.selectedDate)
            }
        }
    },
    computed:{
        selectedDate(){
            if(this.day > 0 && this.monthIndex > -1  && this.year){
                let d = new Date(this.year, this.monthIndex, this.day);
                console.log('from selected date:', this.year, this.monthIndex, this.day)
                if(this.selectedDateChanged){
                    this.selectedDateChanged(d)
                }
                return d;
            }
            return new Date();
        }
    },
    props:["selectedDateChanged", "startDay", "startMonthIndex", "startYear"]
}
</script>

<style lang="scss" scoped>

</style>