<template>
    <div class="col-span-1">
        <div class="flex-1 items-center group "> 
            <div class="flex w-full h-20 w-20 z-1 m-1" :style="bgImage" > 
                <div class="flex w-full items-center justify-center">
                    <i :class='icon'></i>
                </div>
            </div>
            <div class='flex'>
                <div class='flex-grow'></div>
                <div class=" items-center mx-auto"> 
                    <div v-if="(stepIdx !== length) || (step.type !== '')" :class="`top-4 left-4 w-0.5 h-16 ${timelineColor}`" aria-hidden="true" />
                </div>
                <div class='flex-grow'></div>
            </div>
        </div>
    </div>
    <div class="col-span-2 shadow-xl rounded-xl ">
        <div class="text-left m-2 p-2 pt-5">
            <div class="text-xs tracking-wide uppercase text-gray-500">{{ step.name }}</div>
            <div class="flex space-x-5">
                <div class="text-md text-gray-500 font-semibold tracking-wide uppercase">{{ step.manufacturer }}</div>
                <div class="text-md text-gray-500">{{ step.lotID }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import UIHelper from "./../../../../helpers/UIHelper.js";
const blueCircleSrc = '/images/circles/BluePurpleDoubleCircle400.png';
const greenCircleSrc = '/images/circles/GreenGreenDoubleCircle400.png';
const grayCircleSrc = '/images/circles/GrayGrayDoubleCircle400.png';
export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            blueCircleSrc,
            ...toRefs(state),
        }
    },
    methods: {
       
    },
    computed:{
        bgImage() {
            if(this.step.status === 'pending'){return UIHelper.inlineBgImage(grayCircleSrc, 75, 75)}
            if(this.step.status === 'complete'){return UIHelper.inlineBgImage(greenCircleSrc, 75, 75)}
            else if(this.tokenCount === 0) return UIHelper.inlineBgImage(blueCircleSrc, 75, 75);
            else if (this.tokenCount === 1) return UIHelper.inlineBgImage(greenCircleSrc, 75, 75);
            else return UIHelper.inlineBgImage(grayCircleSrc, 75, 75);
        },
        icon(){
            if (this.step.status === 'complete'){return "fa fa-check text-green-400 pt-50"}
            else if(this.step.status === 'current'){return "fa fa-syringe pt-50 text-purple-500 animate-pulse"}
            return "fa fa-syringe pt-50"
        },
        tokenCount(){
            return this.$store.state.tokens ? this.$store.state.tokens.length : 0;
        },
        timelineColor(){
            if (this.step.status === 'complete') {
                return "bg-green-400"
            } else if(this.step.status === 'current'){
                return "bg-indigo-600"; 
            }
            return "bg-gray-600";
        }
    },
    props:["step", "stepIdx", "length"]
}
</script>

<style lang="scss" scoped>

</style>