const constants = require('./../constants/ApplicationConstants');
module.exports = {
    //Defaults
    appConstants(){
        return constants;
    },
    //Keys
    paymentPrivateKey(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_PAYMENT_PRIVATE_KEY;}
        return constants.TEST_PAYMENT_PRIVATE_KEY;
    },
    adminOwnerPrivateKey(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_ADMIN_OWNER_PRIVATE_KEY;}
        return constants.TEST_ADMIN_OWNER_PRIVATE_KEY;
    },
    providerPrivateKey(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_PROVIDER_PRIVATE_KEY;}
        return constants.TEST_PROVIDER_PRIVATE_KEY;
    },
    patientPrivateKey(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_PATIENT_PRIVATE_KEY;}
        return constants.TEST_PATIENT_PRIVATE_KEY;
    },
    //Locations
    vaccinationRecordClassLocation(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_VACCINATION_RECORD_CLASS_LOCATION;}
        return constants.TEST_VACCINATION_RECORD_CLASS_LOCATION;
    },
    credentialClassLocation(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_CREDENTIAL_CLASS_LOCATION;}
        return constants.TEST_CREDENTIAL_CLASS_LOCATION;
    },
    medicalCredentialFactoryClassLocation(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_MEDICAL_CREDENTIAL_FACTORY_CLASS_LOCATION;}
        return constants.TEST_MEDICAL_CREDENTIAL_FACTORY_CLASS_LOCATION;
    },
    //Origins 
    medicalCredentialFactoryClassOrigin(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN;}
        return constants.TEST_MEDICAL_CREDENTIAL_FACTORY_CLASS_ORIGIN;
    },
    credentialClassOrigin(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_CREDENTIAL_CLASS_ORIGIN;}
        return constants.TEST_CREDENTIAL_CLASS_ORIGIN;
    },
    vaccinationRecordClassOrigin(_network){
        if(_network === constants.MAIN_NETWORK){return constants.MAIN_VACCINATION_RECORD_CLASS_ORIGIN;}
        return constants.TEST_VACCINATION_RECORD_CLASS_ORIGIN;
    },
    //Medical 
    vaccineManufacturers(){
        return constants.VACCINE_MANUFACTURERS;
    },
    doseInterval(_mfg){
        return constants.VACCINE_DOSE_INTERVAL[_mfg];
    },
    ndcCode(_dose, _mfg){
        if(_dose === 1){ return constants.VACCINE_NDC_CODES_1[_mfg]}
        else if(_dose === 2){return constants.VACCINE_NDC_CODES_2[_mfg]}
        return 0;
    }
}