<template>
<logged-in> 
        <template v-slot:content >
        <div class='max-w-screen'>
            <div class="bg-white m-auto md:w-1/2 montserrat rounded-xl shadow-xl h-full w-full m-2 p-2 justify-center">
                <div class='flex pt-36 pb-10'>
                    <div class='w-1/3'></div>
                    <div class='w-1/3'>
                        <img style='' src="./../../../assets/images/radial-logo.png" />
                    </div>
                    <div class='w-1/3'></div>
                </div>
                <div class='pb-10 w-full text-center'>
                     <div class='flex pt-10'>
                         <div class="text-indigo-600 w-1/3">
                            <button @click="navigateTo('/apps/patient/covid')" >
                             <div class='w-8 m-auto'> <ColorSwatchIcon /> </div>
                             <div class='text-gray-700 font-light'> RECORDS </div>
                            </button>
                        </div>
                         <div class="text-indigo-600 w-1/3">  
                         <button @click="navigateTo('/apps/patient/profile')" >
                             <div class='w-8 m-auto'> <UserIcon /> </div>
                             <div class='text-gray-700 font-light'> Profile </div>
                             </button>
                        </div>
                         <div class="text-indigo-600 w-1/3">
                            <button @click="navigateTo('/apps/patient/keys')" >
                               <div class='w-8 m-auto'> <KeyIcon /> </div>
                                 <div class='text-gray-700 font-light'> Keys </div>
                             </button>
                         </div>
                     </div>
                </div>
                <div class='sm:w-full py-4'>
                    <div class=' bg-gradient-to-r from-pink-500 to-indigo-500 rounded-xl'>
                        <div class='py-4 text-xl text-white font-medium font-600 p-2 m-2 '>
                            HOW ARE MY RECORDS SAFER ON BLOCKCHAIN?
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </template>
</logged-in>
</template>
<script>
import { reactive, toRefs } from 'vue'
import LoggedIn from './layouts/LoggedIn.vue'
import Language from "./../../../constants/language/english.js";
import {
  KeyIcon,
  ColorSwatchIcon,
  UserIcon,
} from '@heroicons/vue/outline'

export default {
    components: {LoggedIn, KeyIcon, ColorSwatchIcon, UserIcon},
    setup () {
        console.log(Language.SAVE_VACCINATION_RECORDS)
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    },
    methods:{
        navigateTo(path){
            this.$router.push(path)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>