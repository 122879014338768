<template>
<div> 
<div v-if="isAdmin"> 
    <menu-dark-base/>
  <Suspense> 
    <template #default >
      <router-view/>
    </template>
    <template #fallback >
      <div class=""> Loading ... </div>
    </template>
  </Suspense>
  </div>
  <div v-else-if="isPatient"> 
    <Suspense> 
      <template #default >
        <router-view />
      </template>
      <template #fallback >
        <div class=""> Loading ... </div>
      </template>
    </Suspense>
  </div>
  <div v-else-if="isProvider"> 
    <Suspense> 
      <template #default >
        <router-view/>
      </template>
      <template #fallback >
        <div class=""> Loading ... </div>
      </template>
    </Suspense>
  </div>
   <div v-else-if="isLite"> 
    <Suspense> 
      <template #default >
        <router-view/>
      </template>
      <template #fallback >
        <div> 
        <loading-panel></loading-panel>
        <Footer></Footer>
        </div>
      </template>
    </Suspense>
  </div>
  <div v-else>  <!-- No Known Type --> 
    <Suspense> 
      <template #default >
        <router-view/>
      </template>
      <template #fallback >
        <loading-panel></loading-panel>

      </template>
    </Suspense>
  </div>
  </div>
</template>

<script> 
import {provide} from "vue";
import useBreakpoints from "./composition/useBreakpoints.js"
import MenuDarkBase from "./components/tailwind/menus/MenuDarkBase.vue"
import LoadingPanel from "./components/apps/lite/LoadingPanel.vue"
import ConstantsManager from "./managers/ConstantsManager.js";
import Footer from './components/apps/lite/Footer.vue';
import Language from "./constants/language/english";
export default {
  name: "App",
  setup(){
    let {width, type} = useBreakpoints();
    provide('Language', Language)
    provide('screen-width', width);
    provide('screen-size', type);
    return({
      width,
      type
    })
  },
  mounted(){
    // if(this.$store.state.network !== this.network){
    //   this.$store.commit("setNetwork", this.network)
    //   console.log(this.$store.state.network)
    // }
  },
  computed:{
    isAdmin(){
      
      let _is = this.$route.path.includes('admin');
      return _is;
    },
    isProvider(){
      let _is = this.$route.path.includes('provider');
      return _is
    },
    isPatient(){
      let _is = this.$route.path.includes('patient');
      return _is;
    },
    isLite(){
      let _is = this.$route.path.includes('lite');
      return _is;
    },
    network(){
      return ConstantsManager.appConstants().TEST_NETWORK;
    },
    XS(){if (this.screenSize === "xs") return true; else {return false}},
    SM(){if (this.screenSize === "sm") return true; else {return false}},
    MD(){if (this.screenSize === "md") return true; else {return false}},
    LG(){if (this.screenSize === "lg") return true; else {return false}},
    XL(){if (this.screenSize === "xl") return true; else {return false}},
    
  }, 
  components:{
    MenuDarkBase,
    Footer,
    LoadingPanel,
  },
}
</script> 

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: 'Montserrat', sans-serif;
}


</style>
