<template>
    <div class="sm:flex sm:items-center sm:justify-between">
        <div class="sm:flex sm:space-x-5">
            <div class="flex-shrink-0">
                <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="user.imageUrl" alt="" /> -->
            </div>
            <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p class="text-2xl font-semibold text-gray-600 sm:text-3xl">{{ Language.PROVIDER_PROFILE}}</p>
                <p class="text-sm font-medium text-gray-600">{{  }}</p>
            </div>
        </div>
        <div class="mt-5 flex justify-center sm:mt-0">
        <button @click="navigateToKeys" class="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            View Keys
        </button>
        </div>
    </div>
</template>

<script>
import { inject, reactive, toRefs } from 'vue'

export default {
    setup () {
        const Language = inject('Language');
        const state = reactive({
            count: 0,
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    methods: {
        navigateToKeys(){this.$router.push('/apps/provider/keys');}
    }
}
</script>

<style lang="scss" scoped>

</style>