<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="open">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:max-w-xl sm:w-full md:max-w-2xl">
           <div class='w-full grid grid-cols-2 text-center'>

               <div v-if="!showPatientQRCode" class='col-span-1 bg-gray-200 flex items-center'>
                   <div class='flex-1'>
                    <div class='text-center text-sm w-full text-indigo-600 px-16 py-4'>
                        Print the login code and give it to the patient. 
                    </div>
                    <div class='text-center'>
                          <button type="button" @click="toggleQR" class="w-4/5 inline-flex items-center px-6 py-3 mx-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                              <div class='w-full'>PRINT QR Code</div>
                          </button>

                          <button type="button" @click="toggleSeed" class="w-4/5 inline-flex items-center px-6 py-3 mx-3 my-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                              <div class='w-full'>Show Login Code</div>
                          </button>
                    </div>
                    <div class='py-4'>
                       <p class="text-red-500 text-sm"><input type='checkbox' :checked="isChecked" @click="enableSubmit" /> I have provided this login info to the patient.</p>
                    </div>
                     <div v-if="showSeed">
                            <p class='text-sm text-gray-500'>{{store.state.patientSeed}}</p>
                      </div> 
                   </div>
               </div>
               <div v-if="!showPatientQRCode" class='col-span-1'>
                   <div class="py-2 my-4 mx-1 text-xs text-gray-600 text-left">
                       Once submitted the details of this confirmation can not be changed. Please verify that all information is complete and accurate.
                    <hr/>
                   </div>
                   <div class='grid grid-cols-2'>
                       <div class='col-span-1 pl-2'>
                           <div class="text-left pl-1">
                               <span class='text-xs font-medium'> Patient Name </span>
                               <br/>
                               <span class='text-sm font-normal text-gray-500'> {{store.state.patientFirstName}} {{store.state.patientMiddleInitial}} {{store.state.patientLastName}} </span>
                           </div>
                            <div class="text-left pl-1">
                               <span class='text-xs font-medium'> Date of Birth </span>
                               <br/>
                               <span class='text-sm font-normal text-gray-500'> {{store.state.patientBirthday.toLocaleDateString('EN-US')}} </span>
                           </div>
                           <div class="text-left pl-1">
                               <span class='text-xs font-medium'> State ID </span>
                               <br/>
                               <span class='text-sm font-normal text-gray-500'> {{store.state.patientStateID ? store.state.patientStateID  : "Not Provided"}} </span>
                           </div>
                           <div class="text-left pl-1">
                               <span class='text-xs font-medium'> Passport Number </span>
                               <br/>
                               <span class='text-sm font-normal text-gray-500'> {{store.state.patientPassportNumber ? store.state.patientPassportNumber : "Not Provided"}}</span>
                           </div>
                       </div>
                       <div class='col-span-1'>
                           <div class="flex text-left">
                               <div class="text-indigo-600 text-xl p-1">
                                   <i class='fa fa-syringe'></i>
                               </div>
                               <div class="p-1">
                                   <div class="text-md">{{store.state.dose1Manufacturer}}</div>
                                   <div class="text-sm pb-1">Administered:</div>
                                   <div class="text-sm">{{store.state.dose1DistributedDate ? new Date(store.state.dose1DistributedDate).toLocaleDateString("EN-US") : ""}}</div>
                                   <div class="text-sm pt-1">Lot # {{store.state.dose1LotID}}</div>
                                   <div class="text-sm pt-1">{{store.state.dose1Site}}</div>
                               </div>
                           </div>
                            <div class="flex text-left pt-2">
                               <div class="text-indigo-600 text-xl p-1">
                                   <i class='fa fa-syringe'></i>
                               </div>
                               <div class="p-1">
                                   <div class="text-md">{{store.state.dose2Manufacturer}}</div>
                                   <div class="text-sm pb-1">Administered:</div>
                                   <div class="text-sm">{{store.state.dose2DistributedDate ? new Date(store.state.dose2DistributedDate).toLocaleDateString("EN-US") : ""}}</div>
                                   <div class="text-sm pt-1">Lot # {{store.state.dose2LotID}}</div>
                                   <div class="text-sm pt-1">{{store.state.dose2Site}}</div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="flex pt-4">
                       <div class="w-full p-2 m-1 text-sm">
                       <button @click="cancel" class='bg-gray-400 rounded w-full font-light p-2'>Cancel </button>
                       </div>
                       <div class="w-full p-1 m-1">
                        <button v-if="isChecked"
                          @click="confirm" 
                          class='rounded w-full bg-blue-200 font-light p-2'>
                          SUBMIT 
                        </button>
                       </div>
                   </div>
               </div>
             <div class="p-4 m-4 flex w-full col-span-2" :class="qrClass">
               <div class='w-full'>
                 <canvas id="qr1" class="mx-auto"></canvas>
                 <button @click="toggleQR" class='rounded p-2 bg-red-500 text-white'> Hide QR Code </button>
               </div>
               <div class='w-full flex  items-center'>
                 <div class='mx-auto'>
                 <button type="button" @click="print" class="w-4/5 inline-flex items-center px-6 py-3 mx-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                      <div class='w-full'>PRINT</div>
                  </button>
                  </div>
               </div>
             </div>
           </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {ref} from "vue"
import { Dialog, DialogOverlay, 
//DialogTitle, 
TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useStore } from "vuex";
//import { CheckIcon } from '@heroicons/vue/outline'
import QRious from "qrious";
import QRHelper from "./../../../../helpers/QRHelper.js";

export default {
  components: {
    Dialog,
    DialogOverlay,
    //DialogTitle,
    TransitionChild,
    TransitionRoot,
    //CheckIcon,
  },
  setup() {
    let store = useStore()
    let showPatientQRCode = ref(false);
    let isChecked = ref(false);
    let showSeed = ref(false)
    const showPatientQR = () => {
      if(showPatientQRCode.value){
        let toEmbed = QRHelper.createPatientQRData( store.state.patientAddress, 
                                                    store.state.patientFirstName, 
                                                    store.state.patientMiddleInitial, 
                                                    store.state.patientLastName, 
                                                    store.state.patientBirthday,
                                                    store.state.patientRace,
                                                    store.state.patientGender,
                                                    store.state.patientRedeemCode,
                                                    store.state.patientSeed)
        new QRious({
            element: document.getElementById("qr1"),
            value: toEmbed,
            size: '300'
         });
     }
    }
    return {isChecked, showSeed, store, showPatientQRCode, showPatientQR}
  },
  mounted(){
     
  },
  methods:{
    confirm(){
      this.$emit("modal-confirmed");
    },
    cancel(){
      this.$emit("modal-canceled");
    },
    toggleQR(){
      this.showPatientQRCode =  !this.showPatientQRCode;
      if(this.showPatientQRCode){
        this.showPatientQR();
      }               
    },
    toggleSeed(){
      this.showSeed =  !this.showSeed;       
    },
    enableSubmit(){
      this.isChecked = !this.isChecked;
      console.log('submit enabled')
    },
    print(){
      window.print();
    }
  },
  computed:{
    qrClass(){
      if(this.showPatientQRCode){return ''}
      return 'hidden'
    },
    submitEnabled(){
      return this.isChecked
    }
  },
  props: {
    open: Boolean,
    title: String,
    message: String,
    confirmText: String,
  }
}
</script>