module.exports = {
    inlineBgImage(src, width, height) {
        let bgImage = require('@/assets' + src)

        return {
            backgroundImage: `url("${bgImage}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "center", 
            backgroundSize: `${width}px ${height}px`,
        }
    }
}