<template>
    <div>
        <header-and-footer-panel>
            <template v-slot:header>
                <div class='text-center p-2 m-2'><img class="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0 mx-auto " :src="selectedFactory.logoUrl" /></div>
                <div class='text-2xl'> {{selectedFactory.organization}}</div>
                <div class="text-md"> {{selectedFactory.description}}</div>
                <div class="text-lg"> {{selectedFactory.state}}, {{selectedFactory.country}}</div>
                <div class="text-lg"> {{selectedFactory.owner}}</div>
                <div class=""> <a class='text-blue-500' target="_blank" rel="noreferrer noopener" :href="`https://run.network/explorer/?query=${selectedFactory.location}&network=test`"> View on Chain </a> </div>
            </template>
             <template v-slot:content>
                <add-credential> </add-credential>
                <div class="flex-none m-2">
                    <div class='scroll-y-auto '>
                        <dl v-for="c in credentials" :key="c" class="">
                            <div class="grid-cols-3 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 justify-items-center">
                                <div class="text-sm font-medium text-gray-500">
                                    {{c[0]}}
                                </div>
                                <div class="mt-1 text-sm md:text-left text-gray-900 sm:mt-0 ">
                                    {{c[1]}}
                                    
                                </div> 
                                <div class='text-sm text-gray-900 sm:mt-0 xs:col-span-3  '> 
                                       <div class="max-h-4 max-w-4" >
                                               <a target="_blank" rel="noreferrer noopener" :href="`https://run.network/explorer/?query=${c[1]}&network=test`" class="max-h-4 max-w-4 text-xs">View</a>
                                       </div>
                                   
                                </div>
                            </div>
                        </dl>
                    </div>
                 </div>
             </template>
        <template v-slot:footer>Total Updates: {{selectedFactory.nonce}}</template>
        </header-and-footer-panel>
    </div>
</template>



<script>

import { useStore, mapState } from 'vuex'
import {useRoute} from "vue-router"
import HeaderAndFooterPanel from "./../../../../components/tailwind/panels/HeaderAndFooter.vue"
import AddCredential from './../../../../components/apps/admin/factories/AddCredential.vue'
//import {EyeIcon} from "@heroicons/vue/outline"
export default {
    async setup(){
        let route = useRoute();
        let store = useStore();
        let _location = route.query.id;
        console.log({_location});
        await store.dispatch("loadSelectedFactory", _location);
    },
    computed:{
        credentials(){
            let creds =  this.selectedFactory.credentials;
            let collection = []
            let i = 0
            for (let key in creds ) {
                collection[i] = [key, creds[key]]
                i++
            }
            return collection
        },
        ...mapState(['selectedFactory'])
    },
    components:{
        HeaderAndFooterPanel,
        AddCredential,
        //EyeIcon
    }
}
</script>

<style lang="scss" scoped>

</style>