<template>
    {{doseTotalForDisplay}}
    <div class='flex-1 space-y-2'>
        <div v-if="redeemTokenFactory"> {{redeemTokenFactory.name}}</div>
        <div class="">
            <button @click="createRedeemTokenFactory('Redeem Token Factory')">Create Redeem Token Factory </button>
        </div>
        <div class='space-y-10'> </div>
        <div class="">
            <button @click="createRedeemTokenFromFactory(randomAddress)">Create Token </button>
        </div>
        <div class='space-y-10'> </div>
        <div class="">
            <button @click="clearPatientDetails">Clear Patient Details </button>
        </div>

        {{store.state.patientSeed}}<br/>
        {{store.state.patientPrivKey}}<br/>
        {{store.state.patientAddress}}<br/>
        {{store.state.liteSeed}}<br/>

        <div class="flex-1 ">
            <div class="">
            {{store.state.network}}
            </div>
            <div class="">
            <button @click="setNetwork(testNet)">Change to Test </button>
            </div>
            <div class="">
            <button @click="setNetwork(mainNet)">Change to Main </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import {useStore} from "vuex"
import Run from "run-sdk"
//import bsv from "bsv"
import ConstantsManager from "./../../../managers/ConstantsManager.js";
let Token = Run.extra.Token;
export class RedeemTokenFactory extends Run.Jig{
    init(name){
        this.name = name;
    }
    createRedeemToken(to){
        return new RedeemToken(to)
    }
}
export class RedeemToken extends Token {
    init(to){
        this.burned = false 
        this.owner = to
        // eslint-disable-next-line no-undef
        this.factory = caller;
    }
}

RedeemTokenFactory.deps = {RedeemToken};

export default {
    setup () {
        let redeemTokenFactory = ref(null);
        let store = useStore();
        let run = new Run({network: store.state.network})
        const state = reactive({
            count: 0,
            randomAddress: run.owner.address,
        })
    
        return {
            store,
            redeemTokenFactory,
            ...toRefs(state),
        }
    },
    methods:{
        async createRedeemTokenFactory(name){
            let _network = this.$store.state.network;
            let run = new Run({network: _network, owner: ConstantsManager.adminOwnerPrivateKey(_network), purse: ConstantsManager.paymentPrivateKey(_network), trust: "*"})
            run.activate();
            await run.inventory.sync();
            let tx = new Run.Transaction();
             tx.update(() => new RedeemTokenFactory(name));
            let response = await tx.publish();
            console.log(response);
            return response; 
        },
        async createRedeemTokenFromFactory(to){
            let _network = this.$store.state.network;
            let run = new Run({network: _network, owner: ConstantsManager.adminOwnerPrivateKey(_network), purse: ConstantsManager.paymentPrivateKey(_network), trust: "*"})
            await run.inventory.sync();
            let _factory = null;
            run.inventory.jigs.forEach(j => {
                //TODO: Make this static shit Dynamic, WTF was I thinking? 
                if(j.origin === "856af2ff416783bfd4c02347e360b975f590dcf6f0ef034d451b3577e1c8b1ab_o3"){
                    _factory = j;
                }
            })
            await _factory.sync();
            console.log(_factory, to);
            
            let response = await _factory.createRedeemToken(to);
            console.log(response)
        },
        async clearPatientDetails(){
            await this.$store.dispatch("clearPatientPersonalData");
            await this.$store.dispatch("clearPatientDoseData");
        },
        setNetwork(_net){
            this.$store.commit("setNetwork", _net)
        }
    },
    computed:{
            changeNetworkButtonText(){
                if(this.$store.state.network === this.testNet){
                    return ConstantsManager.appConstants().MAIN_NETWORK;
                }
                return ConstantsManager.appConstants().TEST_NETWORK;
            }, 
            doseTotalForDisplay(){
                return ConstantsManager.appConstants().VACCINE_DOSE_TOTAL;
            },
            testNet(){
                return ConstantsManager.appConstants().TEST_NETWORK;
            },
            mainNet(){
                return ConstantsManager.appConstants().MAIN_NETWORK;
            }
        }
}
</script>

<style lang="scss" scoped>

</style>