<template>
    <LoggedInWide >
        <template v-slot:welcomePanel >
            <div class="flex rounded-xl bg-white shadow items-center"> 
                <div class="welcome-left w-full p-4 m-4  grid grid-cols-3 justify-right items-end">
                    <div class=' col-span-2 text-left w-full'> 
                        <label class='text-pink-600 text-xl font-lite '> VERIFY REDEMPTION CODE </label> 
                        <input class='rounded ring-1 ring-gray-400 h-10 w-full mt-2' v-model='redemptionCode' placeholder="   Paste Code Here">
                    </div> 
                    <div class='flex col-span-1 items-center'>
                        <div class=''> <button @click="checkCode" class='rounded bg-pink-500 text-white text-md font-normal p-2 h-10 break-none ml-2 font-medium' > CHECK CODE </button></div>
                    </div>
                    
                </div>
                <!-- <div class="welcome-middle w-12">y</div> -->
                <div class="welcome-right w-full items-stretch grid justify-items-end p-4 m-4 ">
                    <div class='flex items-stretch'> 
                        <button class='bg-pink-500 text-white p-2 m-2 rounded'> 
                            <div class='w-full'><div> <QrcodeIcon class="max-h-12 mx-auto" /> </div></div>
                            <div>SCAN QR</div>
                        </button>
                         <button @click='createPatient' class='bg-pink-500 text-white p-2 m-2 font-normal rounded'> 
                            <div class='font-bold'>+</div>
                            <div>NEW</div>
                            <div>PATIENT</div>
                        </button>
                    </div>
                </div>
            </div>
        </template>
         <template v-slot:actionsPanel >
             <div class='actions-wrapper w-full grid grid-cols-2'>
                 <div class='col-span-1 px-1'>
                     <div class='patient-form-wrapper rounded-lg text-white p-6 m-4' :class="patientInfoContainerClasses"> 
                         <div class='w-full p-2 flex items-center'>
                             <div class='flex w-full'> 
                             <div> <UserIcon class='max-h-6'/> </div> 
                             <div class='text-lg pl-2 break-none'> PATIENT INFORMATION</div>
                             </div>
                             <div class='grid justify-items-end w-full'> <div> <button @click="this.editPatient = true"> EDIT </button> </div> </div>
                        </div>
                         <hr class='h-1 font-bold'/>
                         <div v-if="editPatient">
                            <div class='input-group items-left text-left'>
                                <div class='px-4'>
                                <label class='text-md font-medium'> Account Key</label>
                                <input :disabled="addressDisabled"  v-model="localPatientAddress" class='h-8 rounded w-full text-gray-800 text-sm pl-3' />
                                </div> 
                            </div>
                            <div class='input-group items-left text-left'>
                                <div class='py-1 px-4'>
                                <label class='text-md font-medium'> First Name</label>
                                <input v-model="firstName" class='h-8 rounded w-full text-gray-800 text-sm pl-3' />
                                </div> 
                            </div>
                            <div class='input-group items-left text-left'>
                                <div class='py-1 px-4'>
                                <label class='text-md font-medium'> Middle Name</label>
                                <input  v-model="middleName" class='h-8 rounded w-full text-gray-800 text-sm pl-3' />
                                </div> 
                            </div>
                            <div class='input-group items-left text-left'>
                                <div class='py-1 px-4'>
                                <label class='text-md font-medium'> Last Name</label>
                                <input  v-model="lastName" class='h-8 rounded w-full text-gray-800 text-sm pl-3' />
                                </div> 
                            </div>
                            <div class=''>
                                <div class='py-1 px-4 '>
                                    <DateSelection 
                                    :selectedDateChanged="birthdayChanged" 
                                    :startDay="birthdayDay" 
                                    :startMonthIndex="birthdayMonth"
                                    :startYear="birthdayYear"/>
                                </div>
                            </div>
                            <div class='patient-form-submit grid justify-items-end w-full pb-12'>
                                <div class='flex pr-6  pt-4 font-medium'>
                                    <button @click="savePatientDetails"> SAVE </button>
                                    <div class='w-12'></div>
                                    <button @click="togglePatientForm(false)"> CANCEL </button>
                                </div> 
                            </div>
                         </div>
                         <div v-else class='w-full p-2'>  
                             <div class='input-group items-left text-left'>
                                <div class='px-4'>
                                <div class='text-sm font-semibold'> Account Key</div>
                                <div class='h-8 rounded w-full text-xs ' > {{patientAddress}} </div>
                                </div> 
                            </div>
                            <div class='input-group items-left text-left'>
                                <div class='px-4'>
                                <div class='text-sm font-semibold'> Patient Name</div>
                                <div class='h-8 rounded w-full text-xs'> {{patientName}}</div>
                                </div> 
                            </div>
                            <div class='input-group items-left text-left'>
                                <div class='px-4'>
                                <div class='text-sm font-semibold'> Date Of Birth</div>
                                <div class='h-8 rounded w-full text-xs'> {{birthdayDisplay }}</div>
                                </div> 
                            </div>
                            
                         </div>
                    </div>  

                    <div v-if="newDoses.length > 0" class="doses text-gray-600">
                             <div class='text-lg text-pink-500 font-semibold text-left'> READY TO SAVE </div>
                            <div v-for="(dose, index) in newDoses" :key="index" class='bg-warm-gray-300 text-gray-900 rounded-xl mb-3'>
                                <div class='float-right'> <button class='text-red-600 p-2' @click="removeDoseFromCollection(index)">x </button> </div>
                                <div class='grid grid-cols-4 text-xs bg-warm-gray-300 text-gray-900 rounded-xl p-4 text-left'> 
                                    <div class='col-span-1 '> <div class='font-medium text-gray-900 text-sm'> Manufacturer</div>  {{dose.manufacturer}} </div>
                                    <div class='col-span-1'> <div class='font-medium text-gray-900 text-sm'> Lot Number</div> {{dose.lot}} </div>
                                    <div class='col-span-1'> <div class='font-medium text-gray-900 text-sm'> Administered</div>  {{dose.date ? dose.date.toLocaleDateString("EN-US") : ""}} </div>
                                     <div class='col-span-1'> <div class='font-medium text-gray-900 text-sm'> Provider/Clinic</div>  {{dose.site}} </div>
                                </div>
                            </div> 
                         </div>
                    <div class='grid justify-items-start'>
                        <div><button @click='clearAll' class='bg-red-600 text-white rounded p-2 m-2 flex'> <RefreshIcon class='h-6 pr-1'/> CLEAR ALL </button></div>
                    </div>
                         <!-- TODO:  Add a remove dose button to the list ---> 
                 </div>
                 <div class='col-span-1 px-1'>
                     <div class='rounded-lg text-white shadow-xl'>
                       <div class='w-full p-2 flex items-center '>
                             <!-- <div> <UserIcon class='max-h-6'/> </div>  -->
                             <div class='text-lg p-2 m-4 text-indigo-600 '>DOSE INFORMATION</div>
                        </div>
                         <hr />
                         <div class="m-3">
                             <div class='flex'>
                                <div class='w-full'>
                                    <div class=" text-left px-1 bg-white text-sm font-medium text-gray-900">{{Language.MANUFACTURER}}*</div>
                                    <Listbox as="div" v-model="selectedManufacturer"  class='text-gray-600 ring-1 ring-gray-400 rounded mr-2'>
                                        <div class=" relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                                            <span class="block truncate">{{ selectedManufacturer }}</span>
                                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>
                                    
                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            <ListboxOption as="template" v-for="mfg in manufacturers" :key="mfg" :value="mfg" v-slot="{ active, selectedManufacturer }">
                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                                <span :class="[selectedManufacturer ? 'font-semibold' : 'font-normal', 'block truncate']">
                                                    {{ mfg }}
                                                </span>

                                                <span v-if="selectedManufacturer" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute z-8 inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                </li>
                                            </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                        </div>
                                    </Listbox>
                                 </div>
                                 <div class='w-full'>
                                      <div class="align-bottom">
                                         <div class=" text-left px-1 bg-white text-sm font-medium text-gray-900"> {{Language.LOT_ID}}*</div>
                                        <input v-model="lotID" @change="lotIDChanged" type="text" name="lotID" id="lotID" class="ring-gray-400 ring-1 block w-full rounded  p-2 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" style='border:none' placeholder="LOT ID" />
                                    </div>
                                 </div>
                            </div>
                            <div class='mt-2'> 
                                <div class=" mt-2   text-left px-1 bg-white text-sm font-medium text-gray-900">Date Administered*</div>
                                <DateSelection class='w-full text-gray-900' :selectedDateChanged="administeredDateChanged" :startYear="2020"/>
                            </div>
                            <div class='mt-4'>
                                <div class=" text-left px-1 bg-white text-sm font-medium text-gray-900"> Healthcare Provider or Clinic Name*</div>
                                <input v-model="site" type="text" name="site" id="site" class="block ring-gray-500 ring-1 w-full rounded  p-1 pt-1 text-gray-900 placeholder-gray-500  focus:ring-gray-400 text-sm" style='border:none' placeholder="Site" />
                            </div>
                            <div class='grid justify-items-end'>
                                <div > <button @click="addDoseToCollection" class='mb-3 bg-indigo-600 text-sm text-white font-medium mt-2 p-2 px-6 rounded'> + ADD DOSE </button> </div>
                            </div> 
                         </div>
                     </div>
                     <div class='pt-5'>
                        <div class='grid grid-cols-2'>
                            <div class='col-span-1 grid justify-items-end'><div class='patientInfoTimelineClass  rounded-full w-8 h-8 grid  pt-0.5 text-white' :class="patientInfoCompleteClasses"> {{patientInfoComplete ? '√' : 'x'}} </div></div>
                            <div class='col-span-1 grid justify-items-start  pl-1 text-left'>
                                <div class='text-xs font-bold'> PATIENT INFORMATION </div>
                                <div class='text-xs' :class='patientInfoCompleteLineClasses'> Required Patient Information {{patientInfoComplete ? "must be" : "is"}}  complete </div>
                            </div>
                            
                        </div>
                        <div class='grid grid-cols-2'>
                            <div class='col-span-1 grid justify-items-end'><div class='patientInfoTimelineClass pr-3' :class="patientInfoCompleteLineClasses"> | </div></div>
                        </div>
                        <div class='grid grid-cols-2'>
                            <div class='col-span-1 grid justify-items-end'><div class='patientInfoTimelineClass rounded-full w-8 h-8 grid pt-0.5 text-white' :class='doseInformationCompleteClasses'> {{doseInformationComplete ? '√' : 'x'}} </div></div>
                            <div class='col-span-1 grid justify-items-start  pl-1'>
                                <div class='text-xs font-bold'> DOSE INFORMATION </div>
                                <div v-if='!doseInformationComplete' class='text-xs' :class="doseInformationCompleteLineClasses ">At least one dose must be added </div>
                            </div>
                            
                        </div>
                        <div class='grid grid-cols-2'>
                            <div class='col-span-1 grid justify-items-end'><div class='patientInfoTimelineClass pr-3' :class="doseInformationCompleteLineClasses"> | </div></div>
                        </div>
                        <div class='grid grid-cols-2'>
                            <div class='col-span-2 w-full grid justify-items-center pr-8 '>
                                <button v-if="patientInfoComplete && doseInformationComplete" @click="openModal" class='patientInfoTimelineClass bg-blue-600 rounded-full w-32 h-32 grid items-justify-center items-center'> <div class='text-white text-lg font-medium'> SUBMIT </div> </button>
                                <button v-else class='patientInfoTimelineClass bg-gray-400 rounded-full w-32 h-32 grid items-justify-center items-center'> <div class='text-white text-lg font-medium'> SUBMIT </div> </button>
                            </div>
                        </div>

                     </div>
                     
                </div> 
             </div>
             <div v-if="confirmation">
             <new-dose-confirmation  :doses="newDoses" v-on:modal-canceled="cancelClicked" v-on:modal-confirmed="confirmClicked"></new-dose-confirmation>
             </div>
         </template>
    </LoggedInWide>
</template>

<script>
import { reactive, toRefs } from 'vue'
import Run from 'run-sdk'
import {useStore} from "vuex"
import LoggedInWide from "./layouts/LoggedInWide.vue" 
import DateSelection from './../../../components/apps/common/DateSelection.vue'
import {QrcodeIcon, UserIcon, RefreshIcon, CheckIcon, SelectorIcon} from '@heroicons/vue/outline'
// import FormMini from "./../../../components/apps/common/dose/FormMini.vue"
import ConstantsManager from "./../../../managers/ConstantsManager.js";
import Language from "./../../../constants/language/english.js";
import Utilities from './../../../helpers/Utilities.js';
import CodeManager from './../../../managers/CodeManager.js'
import VXTransactionHelper from "./../../../helpers/VXTransactionHelper.js";
import NewDoseConfirmation from "./../../../components/apps/provider/NewDoseConfirmation.vue"
import { 
    Listbox, 
    ListboxButton, 
    //ListboxLabel, 
    ListboxOption, 
    ListboxOptions 
} from '@headlessui/vue'
const mfgs = ['-- Please Select --']
Object.entries(ConstantsManager.appConstants().VACCINE_MANUFACTURERS).map((i) => mfgs.push(i[1]));
export default {
    components:{LoggedInWide, QrcodeIcon, UserIcon, RefreshIcon, DateSelection, CheckIcon, SelectorIcon,
     Listbox, 
    ListboxButton, 
    //ListboxLabel, 
    ListboxOption, 
    ListboxOptions, 
    NewDoseConfirmation },
    setup(){
        let store = useStore()
        console.log(store.state.patientSeed)
        
        let _d = "";
        let _edit = false;
        if(store.state.patientBirthday != ''){_d = new Date(store.state.patientBirthday)}
        if(store.state.patientFirstName === ""){_edit = true}
        const state = reactive({
            localPatientAddress: store.state.patientAddress,
            firstName: store.state.patientFirstName,
            lastName: store.state.patientLastName,
            middleName: store.state.patientMiddleInitial,
            birthday: _d,
            editPatient: _edit,
            selectedManufacturer: mfgs[0],
            lotID: "",
            administeredDate: "",
            site: "",
            newDoses:[],
            redemptionCode: "",
            canRedeem: true,
            confirmation: false,
            addressDisabled: false
        })
        console.log( store.state.patientBirthday)
        return {
            ...toRefs(state), Language
        }
    },
    mounted(){

    },
    methods:{
        togglePatientForm(open){
            this.editPatient = open
        },
        birthdayChanged(d){
            console.log("DateChanged:", d)
            this.birthday = d;
        },
        administeredDateChanged(d){
            this.administeredDate = d;
        },
        async savePatientDetails(){
            console.log('saving')
            this.editPatient = false;
            let details = {};
            details.address = this.patientAddress;
            details.firstName = this.firstName;
            details.middleInitial = this.middleName;
            details.lastName = this.lastName;
            details.birthday = this.birthday;
            // details.stateID = this.stateID;
            // details.passportNumber = this.passportNumber;
            await this.$store.dispatch("updatePatientDetails", details);
        },
        addDoseToCollection(){
            let dose = {}
            dose.manufacturer = this.selectedManufacturer;
            dose.date = this.administeredDate;
            dose.site = this.site
            dose.lot = this.lotID
            this.newDoses.push(dose)
            console.log(this.newDoses)
        },
        removeDoseFromCollection(index){
            this.newDoses.splice(index, 1);
        },
        async createPatient(){
            this.addressDisabled = true
            await this.$store.dispatch("clearPatientPersonalData");
            let _patient = Utilities.getRandomAccount();
            let _seed = _patient.seed;
            let _loginType= ConstantsManager.appConstants().LOGIN_TYPE_PATIENT
            await this.$store.dispatch("login", {_seed, _loginType});
            console.log(this.$store.state.patientAddress);
            this.editPatientDetails = true;
            console.log(_patient)
            this.localPatientAddress = this.$store.state.patientAddress
            this.firstName = ""
            this.lastName = ""
            this.middleName = ""
            this.birthday = ""
        },
        async checkCode(){
            let response;
            try{
                response = await CodeManager.checkCode(this.redemptionCode);
                console.log(response);
                if(response.burned){
                    alert('this code has already been used. you will not be paid for this record.')
                }else{
                    this.canRedeem = true;
                }
            }catch(err){
                alert('Code is no good. You will not be paid on this.')
                response = err
            }
            return response;
        },
        async createRecords(){
            let _to = this.$store.state.patientAddress;
            let _network = this.$store.state.network;
            let selectedCredential = null; 
            let _class; 
            let _tx;
            let _run = new Run({network:_network, trust: "*", purse: ConstantsManager.paymentPrivateKey(_network), owner: this.$store.state.providerPrivKey});
            //Activate Run 
            try{
                await _run.activate();
                await _run.sync();
            }
            catch(err){
                console.log("Error Syncing Run", err); 
                 if(err.message.includes('timeout') || err.message.includes('fetch')){
                    alert('timeout error');
                 }
            }
            //Find the related classes on the blockchain 
            try{
                selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
                _class = await _run.load(ConstantsManager.vaccinationRecordClassLocation(_network));
                _class.sync()
               
            }catch(err){
                alert(err);
                console.log(err);
                return;
            }
            _tx = new Run.Transaction()
            for(let i = 0; i < this.newDoses.length; i ++ ){
                let dose = this.newDoses[i];
                let _ndc = ConstantsManager.ndcCode(1, dose.manufacturer);
                let _options = {to: _to, 
                                manufacturer: dose.manufacturer, 
                                ndc: _ndc, 
                                lot: dose.lot, 
                                expiry: "", 
                                distributed: dose.date.toLocaleDateString("EN"), 
                                site: dose.site, 
                                firstName: this.firstName.trim(), mi: this.middleName.trim(), lastName: this.lastName.trim(), birthday: this.birthday}
                 console.log("Dose Details", dose.toString())
                 VXTransactionHelper.addRecordToTransaction(_run, _tx, selectedCredential, _class, _to, _options);
            }
            console.log(_tx)
            try{
                let response = await _tx.publish()
                console.log(response)
                alert(`${this.newDoses.length} records were successsfully added. You may now close this window.`)
            }catch(err){console.log(err); alert(err); return;}
            await this.$store.dispatch("clearPatientPersonalData");
            this.$router.go();
        },
        async clearAll(){
             await this.$store.dispatch("clearPatientPersonalData");
             this.$router.go();
        },
        async confirmClicked(){
            await this.createRecords();
        },
        cancelClicked(){
            this.confirmation = false;
        },
        openModal(){
            this.confirmation = true;
        }
    },
    computed:{
        patientInfoContainerClasses() {
            if(this.editPatient){return 'bg-gray-300'}
            return "bg-indigo-500"
        },
        patientInfoCompleteLineClasses() {
            if(this.patientInfoComplete){return 'text-blue-400'}
            return " text-red-500"
        },
        patientInfoCompleteClasses() {
            if(this.patientInfoComplete){return 'bg-blue-400'}
            return "ring-1 ring-red-500 text-red-500"
        },
        patientInfoComplete(){
            let infoComplete = true;
            if(!this.firstName || this.firstName === ""){infoComplete = false}
            if(!this.lastName || this.lastName === ""){infoComplete = false}
            if(!this.birthday || this.birthday === ""){infoComplete = false}
            return infoComplete
        },
        doseInformationCompleteLineClasses(){
            if(this.doseInformationComplete){return "text-blue-500"}
            return "text-red-500"
        },
        doseInformationCompleteClasses(){
            if(this.doseInformationComplete){return "bg-blue-500"}
            return "ring-1 ring-red-500 text-red-500"
        },
        doseInformationComplete(){
            let infoComplete = true;
            if(this.newDoses.length < 1){infoComplete = false}
            return infoComplete;
        },
        patientAddress(){
            return this.$store.state.patientAddress
        },
        patientName(){
            return this.firstName + " " + this.middleName + " " + this.lastName
        },
        manufacturers(){
            return mfgs
        },
        birthdayDay(){
            console.log(this.birthday)
            if(this.birthday) {return this.birthday.getDate()}
            return 1;
        },
        birthdayMonth(){
            if(this.birthday) {return this.birthday.getMonth()}
            return 0;
        },
        birthdayYear(){
            console.log("Year:", new Date(this.birthday).getYear())
            if(this.birthday) {return this.birthday.getFullYear()}
            return 1;
        },
        birthdayDisplay(){
            console.log("Birthday", this.birthday)
            if(this.birthday && this.birthday != ""){return new Date(this.birthday.toString()).toLocaleDateString("EN")}
            return "";
        }
    }
}
</script>

<style >

</style>