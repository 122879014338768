<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Or
        {{ ' ' }}
        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
          start your 14-day free trial
        </a>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="space-y-6">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              12 Word Login Phrase
            </label>
            <div class="mt-1">
              <input v-model="adminSeed"  id="seed" name="seed" type="" autocomplete="seed" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                
            </div>
          </div>
          <!-- <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div> -->

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div class="text-sm">
              <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your key phrase?
              </a>
            </div>
          </div>

          <div>
            <button @click="login(adminSeed)" type="" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import ConstantsManager from "./../../../managers/ConstantsManager.js";
export default {
    setup () {
        let store = useStore();
        //TODO: Replace this with provide/inject or params 
        let adminSeed = new ref(store.state.adminSeed);
        let router = useRouter();
        console.log(router.currentRoute)
        const login = async (seed) => {
            let _seed = seed.trim();
            console.log({_seed})
            let _loginType = ConstantsManager.appConstants().LOGIN_TYPE_ADMIN;
            console.log({_loginType})
            let response = await store.dispatch("login", {_seed, _loginType});
            console.log({response});
            if(response instanceof Error){
                alert(response);
            }else{
                router.push("/apps/admin/home")
            }
        }
        const state = reactive({
            count: 0,
        })
    
        return {
            login,
            adminSeed,
            ...toRefs(state),
        }
    }, 
    methods:{},
    computed:{}
}
</script>

<style lang="scss" scoped>

</style>