<template>
    <layout pageClasses="bg-gray-50">
        <template v-slot:content>
        <simple-login v-if='store.state.patientSeed === ""' pageClasses="none" >
            <template v-slot:header >
                <div class="flex flex-grid grid-cols-3">
                    <div class='col-span-1 flex-grow items-center justify-center'></div>
                    <div class='col-span-1  items-center justify-center'>
                        <img class="mx-auto h-24 w-auto" src="./../../../assets/images/radial-logo.png" alt="Workflow" />
                        <div class="">
                        <h2 class="pb-2 text-center text-5xl font-semibold text-transparent bg-clip-text bg-gradient-to-b from-pink-400 via-pink-600 to-pink-700">
                        {{Language.SIGN_IN_TEXT}}
                        </h2>
                        </div>
                        <div> 
                            <button 
                             class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                             v-if="store.state.network !== ConstantsManager.appConstants().TEST_NETWORK"  @click="setNetwork(ConstantsManager.appConstants().TEST_NETWORK)"> Set Test Network </button>
                            <button 
                             class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                             v-if="store.state.network !== ConstantsManager.appConstants().MAIN_NETWORK"  @click="setNetwork(ConstantsManager.appConstants().MAIN_NETWORK)"> Set MAIN Network </button>
                        </div> 
                       
                    </div>
                    <div class='col-span-1 flex-grow items-center justify-center'>
                        
                    </div>
                </div>
            </template>
            <template v-slot:form>
                <div class="space-y-6" >
                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">
                        {{Language.SEED_PHRASE}}
                        </label>
                        <div class="mt-1">
                        <input v-model="seed" id="seed" name="seed" type="seed" autocomplete="seed" required="" class="appearance-none block w-full px-3 py-2 ring-2 ring-indigo-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                        <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                        <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                            {{Language.REMEMBER_ME_TEXT}}
                        </label>
                        </div>

                        <div class="text-sm">
                        <button @click="createAccount" class="font-medium text-indigo-600 hover:text-indigo-500">
                            {{Language.SIGN_UP_TEXT}}
                        </button>
                        </div>
                    </div>

                    <div>
                        <button @click="login" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{Language.SIGN_IN_TEXT}}
                        </button>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div> 
                     <button @click="createAccount" type="submit" class=" pt-2 mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        {{Language.SIGN_UP_TEXT}}
                    </button>
                </div>
            </template>
        </simple-login>
        <simple-login v-else pageClasses="none bg-indigo-500" >
            <template v-slot:header >
                <div class="mx-auto h-12 w-auto" >
                    <VXVerified></VXVerified>
                </div>
                <h2 class="mt-6 pt-6 text-center text-3xl font-extrabold text-white">
                    {{Language.SIGN_OUT_TEXT}}
                </h2>
                <p class="mt-2 text-center text-md text-white">
                    {{Language.LOGOUT_EXPLANATION}}
                </p>
                <button @click="returnToApp" class="bg-indigo-500 text-white p-2 m-2 rounded"> <i class="fa fa-arrow-left"></i> Return to App </button>
            </template>
            <template v-slot:form>
                <div class="space-y-6 " >

                  
                </div>
            </template>
            <template v-slot:footer>
                <div  class='bg-gradient-to-r from-purple-400 via-pink-500 to-red-500'> 
                     <button @click="logout" type="submit" class=" pt-2 mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white">
                        <span class='font-extrabold text-xl'>Logout</span>
                    </button>
                </div>
            </template>
        </simple-login>
        </template>
    </layout>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from "vuex";
import Layout from "./Layout.vue"
import SimpleLogin from "./../../../components/tailwind/login/SimpleLogin.vue"
import Language from "./../../../constants/language/english";
//import Utilites from "./../../../helpers/Utilities.js";
import accounts from "./../../../apis/account.js"
import VXVerified from '../../../components/apps/common/svgs/VXVerified.vue';
import ConstantsManager from "./../../../managers/ConstantsManager.js";
export default {
    setup () {
        let store = useStore();
        if(store.state.patientSeed){
            console.log(store.state.patientSeed)
        }
        if(store.state.patientPrivKey){
            console.log(store.state.patientPrivKey)
        }

        const state = reactive({
            seed:store.state.patientSeed,
        })
    
        return {
            ConstantsManager,
            Language,
            ...toRefs(state),
            store,
        }
    },
    methods:{
        login(){
            if(this.seed !== ""){
                try{
                    let _seed = this.seed.trim();
                    let _loginType = ConstantsManager.appConstants().LOGIN_TYPE_PATIENT;
                    this.$store.dispatch("login", {_seed, _loginType})
                    this.$router.push('/apps/patient/keys')
                }catch(err){alert(err)}
            }
        },
        createAccount(){
           let _seed =  accounts.randomSeed()
           this.seed = _seed;
           this.login();
        },
        logout(){
           this.$store.commit("setPatientAddress", "");
           this.$store.commit("setPatientPrivKey", "");
           this.$store.commit("setPatientSeed", "");
           this.$store.commit("setLiteSeed", "");
           this.$store.dispatch("clearPatientPersonalData");
           this.$store.dispatch("clearPatientDoseData");
        },
        returnToApp(){
            this.$router.push('/apps/patient/keys')
        },
        setNetwork(_network){
            console.log(_network);
            this.$store.commit("setNetwork", _network)
        }
    },
    computed:{
        currentNetwork(){
            return this.$store.state.network;
        },
    },
    components:{
        Layout,
        SimpleLogin,
        VXVerified
    }
}
</script>

<style lang="scss" scoped>

</style>