const ConstantsManager = require('./../managers/ConstantsManager.js');
module.exports = {
    async createFactory(_run, country, state, name, description, url, logoUrl){
        _run.activate();
        let _factory;
        try {

            let Factory = await _run.load(ConstantsManager.medicalCredentialFactoryClassOrigin(_run.network))
            _factory = new Factory(country, state, name, description, url, logoUrl)
            await _factory.sync();
            console.log({_factory})
        } catch(err) {
            console.log(err)
        }
        return _factory
    },
    async fetchFactory (_run, sync){
        if(sync || _run.inventory.jigs.length === 0 ){await _run.inventory.sync();}
        console.log(_run.inventory.jigs.length);
        let _factory = _run.inventory.jigs.find((jig) => jig.constructor.origin === ConstantsManager.medicalCredentialFactoryClassOrigin(_run.network))
        return _factory;
    }
}