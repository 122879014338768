<template>
    <LoggedIn >
        <template v-slot:welcomePanel> 
            <div class="rounded-lg bg-white overflow-hidden shadow p-6">
                <h2 class="sr-only" id="profile-overview-title">Covid-19</h2>
                <div class='flex-1 sm:grid sm:grid-cols-4'>
                    <div class="flex-1 bg-white pt-6 col-span-2">
                        <div class="flex  space-x-4"> <!-- sm:justify-between -->
                            <div class="sm:flex sm:space-x-5">
                                <div class="flex-shrink-0">
                                    <img class="h-20 w-20 rounded-full" src="./../../../assets/images/radial-logo.png" alt="" />
                                    <div class="mt-4 pl-2 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                        <p class="text-xl font-medium text-gray-900 sm:text-2xl">Scan</p>
                                        <p class="text-xl font-medium text-gray-900 sm:text-2xl">Patient</p>
                                        <p class="text-sm font-medium text-gray-600">QR Code</p>
                                    </div>
                                </div>
                            </div>
                            <div class='justify-left'>
                                <div>
                                    <qr-stream @decode="onDecode" class="mb stream" :style="streamStyle">
                                        <div style="color: red;" :style="frameStyle"></div>
                                    </qr-stream>
                                    <qr-capture @decode="onDecode" class="mb p-1 m-1 w-48 shadow"></qr-capture>
                                </div>
                                
                            </div>
                        </div>
                        <div class="pt-6">
                            <form-mini :poc="true"></form-mini>
                            <div class="pt-6 m-0 shadow-xl rounded-xl text-left">
                                To Manually Enter an PatientID:
                                <SetPatientAddress :savePatientFunction="updatePatientAddress"/>
                            </div>
                        </div>
                    </div>
                    <div class='col-span-2 p-6'>
                        <protocol-timeline></protocol-timeline>
                    </div>
                </div>
                
            </div>
        </template>
        <template v-slot:actionsPanel>
            <div> </div> 
        </template>
        <template v-slot:rightColumn >
            <section aria-labelledby="announcements-title">
                <div class="rounded-lg bg-white overflow-hidden shadow p-5">
                    <fast-mode></fast-mode>
                    <patient-details></patient-details>
                    <button @click="clearPatient"> Clear Patient details </button>
                </div>
            </section>
        </template>
        <template v-slot:rightColumn2 >
            <div class='flex mx-auto'>
                <div v-if="canSend"> <button @click="sendClicked"> <img class='h-48 w-48' src="./../../../assets/images/send-button.png" /></button></div>
            </div>  
        </template>
    </LoggedIn>
</template>

<script>
import { inject, provide, reactive, toRefs } from 'vue'
import LoggedIn from './layouts/LoggedIn.vue'
import {useStore, mapState} from "vuex";
import ConstantsManager from "./../../../managers/ConstantsManager.js";
import Run from "run-sdk";
import Utilities from "./../../../helpers/Utilities.js"
import SetPatientAddress from "./../../../components/apps/common/SetPatientAddress.vue"
import ProtocolTimeline from "./../../../components/apps/provider/care/ProtocolTimeline.vue"
import FormMini from "./../../../components/apps/common/dose/FormMini.vue"

import FastMode from '../../../components/apps/provider/care/FastMode.vue';
import PatientDetails from '../../../components/apps/provider/care/PatientDetails.vue';
import VXTransactionHelper from "./../../../helpers/VXTransactionHelper.js";
export default {
    components: {
        LoggedIn, SetPatientAddress, ProtocolTimeline, FormMini,
        FastMode,
        PatientDetails
    },
    setup () {
        let store = useStore();
        let width = inject("screen-width");
        let type = inject("screen-size");
        provide('fast-mode', store.state.fastMode);
        const onDecode = async(data) => {
            if(!state.isReading){
                state.isReading = true; 
                console.log(data.toString());
                if(data.toString().length > 0){
                    store.commit('resetPatientTokens');
                    state.data = JSON.parse(data);
                    if(state.data["firstName"]){state.firstName = state.data["firstName"]}
                    if(state.data["middleInitial"]){state.middleInitial = state.data["middleInitial"]}
                    if(state.data["lastName"]){state.lastName = state.data["lastName"]}
                    if(state.data["address"]){state.patientAddress = state.data["address"]}
                    if(state.data["address"]){state.address = state.data["address"]}
                    if(state.data["birthday"]){state.birthday = state.data["birthday"]}
                    if(state.data["race"]){state.race = state.data["race"]}
                    if(state.data["gender"]){state.gender = state.data["gender"]}
                    await store.dispatch("updatePatientDetails", state);
                    await checkForTokensToVerify();
                    state.isReading = false;
                }
            }
        }
        const checkForTokensToVerify = async() =>{
            state.message = "Checking For Tokens..."
            state.tokens.value = [];
            console.log(state);
            let _network = store.state.network;
            if(state.patientAddress === ""){return alert("An ID (address) is required")}
            // /const blockchain = new Run.plugins.WhatsOnChain({ network: _network })
            let _run =  new Run({network:_network, trust:'*', owner: state.patientAddress})
            console.log("Patient Address", state.patientAddress);
            
            await _run.inventory.sync();
            console.log("_run.owner.address: ", _run.owner.address);
            
            let _jigs = _run.inventory.jigs;
            console.log("Jigs", _jigs)
            let credClassOrigin = ConstantsManager.credentialClassOrigin(_network);
            console.log("Cred Class Origin for compare:", credClassOrigin)
            _jigs.forEach( jig => {
                console.log('jig.cred.constructor.origin', jig.cred.constructor.origin);
                if(jig.cred && jig.cred.constructor.origin === credClassOrigin){
                    console.log("This is a VXRecord");
                    state.tokens.push(jig)
                }
            });
            if(state.tokens.length > 0 ){
                store.commit("setPatientTokens", []);
                store.commit("setPatientTokens", state.tokens);
            }
            state.message = "VXPASS Records:";
            if(state.tokens.length === 0 ){state.message = "There are no VXPASS Records to Verify"}
        }
        const state = reactive({
            screenWidth: width,
            screenSize: type,
            patientAddress: store.state.patientAddress,
            firstName: "",
            middleInitial: "",
            lastName: "",
            birthday: "",
            race: "",
            gender: "",
            address: store.state.patientAddress,
            tokens: [],
            message: "",
            hideQR: false,
            data: null,
            isReading: false,
            timetravel: false,
        })
    
        return {
            store,
            onDecode,
            checkForTokensToVerify,
            ...toRefs(state),
        }
    },
    mounted(){
        console.log("Selected Credential:", this.selectedCredential);
        console.log("ENV: ", process.env);
        if(!this.selectedCredential){this.$router.push('/apps/provider/profile')}
    },
    methods:{
        setPatientAddress(){
            this.$store.commit("setPatientAddress", this.patientAddress)
        },
        checkVerify(_token){
            return Utilities.verifyRecordSig(_token);
        },
         checkVerifySig(_token){
            return Utilities.verifyRecordSigwName(_token, this.firstName, this.middleInitial, this.lastName);
        },
        updatePatientAddress(addr){
            console.log("Setting Patient Address: ", addr)
            this.$store.commit("setPatientAddress", addr)
        },
        async sendClicked(){
            let _network = this.$store.state.network;
            let _mfg = this.$store.state.selectedManufacturer;
            let _run = new Run({network:_network, trust: "*", purse: ConstantsManager.paymentPrivateKey(_network), owner: this.$store.state.providerPrivKey});
            let _ndc = ConstantsManager.ndcCode(1, _mfg.replace(' ', ''));
            let _lot = this.$store.state.selectedLotID;
            let _distributed = new Date().toLocaleDateString('en-US');
            console.log("NDC:", _ndc);
            if(this.timetravel){
                let _d = new Date();
                _d.setDate(_d.getDate() - 22);
                _distributed = _d.toLocaleDateString('en-US');
            }
            let options1 = {manufacturer: _mfg, ndc: _ndc, lot: _lot, expiry: this.$store.state.expirationDate, distributed: _distributed, site: "", firstName: this.$store.state.patientFirstName.trim(), mi: this.$store.state.patientMiddleInitial.trim(), lastName: this.$store.state.patientLastName.trim(), birthday: this.$store.state.patientBirthday}
            console.log(options1)
            let selectedCredential = await _run.load(this.$store.state.selectedCredential.location);
            console.log(selectedCredential)
            let _class = await _run.load(ConstantsManager.vaccinationRecordClassLocation(_network));
            await _class.sync()
            let _tx = VXTransactionHelper.setupVXRecordTransaction(_run, selectedCredential, _class, this.$store.state.patientAddress, options1);
            let result;
            try{
                result =  await _tx.publish(); 
                alert('Record Successfully Created')
                this.clearPatient();
            }catch(err){
                alert(err);
            }
            console.log("Result of Send Click on POC", result)
            return result;
        },
        async clearPatient(){
            this.$store.commit("resetPatientTokens")
            await this.$store.dispatch("clearPatientPersonalData")
            await this.$store.dispatch("clearPatientDoseData")
        },
        vaccineInfoComplete(){
            return true;
        },
        patientInfoComplete(){
            let _response = true;
            if(this.firstName.length === 0 || this.middleInitial.length === 0 || this.lastName.length === 0 ){
                console.log("Patient Info Incomplete", this.firstName, this.middleInitial);
                _response = false;
            }
            if(_response){
                if(this.birthday.toString().length === 0){_response = false;}
            }
            return _response; 
        },
        createNewPatient(){

        }
    },
    computed:{
        formedBirthday(){
            if(!this.data)return "";
            if (!this.data.birthday) return "";
            let dateArr = this.data.birthday.split("-");
            return `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
        },
        streamStyle(){
            if(this.screenSize === "SM" || this.screenSize === "XS"){
                return{
                    maxHeight: "175px",
                    maxWidth: "175px",
                    margin: "auto",  
                } 
            } else {
                return{
                    maxHeight: "250px",
                    maxWidth: "250px",
                    margin: "auto",   
                }
            }
        },
        frameStyle(){
            if(this.screenSize === "SM" || this.screenSize === "XS"){
                return {
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: "red",
                    height: "125px",
                    width: "125px",
                    position: "absolute",
                    top: "0px",
                    bottom: "0px",
                    right: "0px",
                    left: "0px",
                    margin: "auto",
                }
            }else{
                return {
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: "red",
                    height: "175px",
                    width: "175px",
                    position: "absolute",
                    top: "0px",
                    bottom: "0px",
                    right: "0px",
                    left: "0px",
                    margin: "auto",
                }
            }
        },
        canSend(){
            //TODO: Implement Checks to see if send is available. 
            let _send = true; 
            if(!this.vaccineInfoComplete){_send = false;}
            if(!this.patientInfoComplete){_send = false;}
            return _send; 
        },
        ...mapState(['selectedCredential'])
    }
}
</script>

<style  scoped>
.stream {
  max-height: 250px;
  max-width: 250px;
  margin: auto;
}
.frame {

}
</style>