module.exports = {
    crypt (salt, text){
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
      
        return text
          .split("")
          .map(textToChars)
          .map(applySaltToChar)
          .map(byteHex)
          .join("");
      },
      
    decrypt(salt, encoded) {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded
          .match(/.{1,2}/g)
          .map((hex) => parseInt(hex, 16))
          .map(applySaltToChar)
          .map((charCode) => String.fromCharCode(charCode))
          .join("");
      },

    encryptOptions(_salt, options){
        console.log("Starting Encryption")
        let encrypted = {};
        let e_Address = module.exports.crypt(_salt, options.to);
        let e_FirstName = module.exports.crypt(_salt, options.firstName);
        let e_MiddleName = module.exports.crypt(_salt, options.mi);
        let e_LastName = module.exports.crypt(_salt, options.lastName);
        let e_MFG = module.exports.crypt(_salt, options.manufacturer);
        let e_LOT = module.exports.crypt(_salt, options.lot);
        let e_EXPIRATION = module.exports.crypt(_salt, options.expiry);
        let e_Distributed = module.exports.crypt(_salt, options.distributed);
        let e_NDC = module.exports.crypt(_salt, options.ndc);
        let e_BIRTHDAY = module.exports.crypt(_salt, options.birthday);
        let e_Site = module.exports.crypt(_salt, options.site);
        let e_LastAttempt = module.exports.crypt(_salt, options.lastAttempt);

        encrypted =  {to: e_Address, manufacturer: e_MFG, ndc: e_NDC, lot: e_LOT, expiry: e_EXPIRATION, distributed: e_Distributed, site: e_Site, firstName: e_FirstName, mi: e_MiddleName, lastName: e_LastName, birthday: e_BIRTHDAY, lastAttempt: e_LastAttempt}
        console.log("Returning Encrypted Object: ", encrypted);
        return encrypted;

    },

    decryptOptions(_salt, options){
        console.log("Starting Decryption")
        let decrypted = {};
        let d_Site =  '';
        let d_FirstName = "";
        let d_MiddleName = "";
        let d_LastName = "";
        let d_BIRTHDAY = "";
        let d_MFG = "";
        let d_LOT = "";
        let d_EXPIRATION = "";
        let d_Distributed = "";
        let d_NDC = "";
        let d_Address = "";
        let d_LastAttempt = "";
        if(options.to.length > 0 ){d_Address = module.exports.decrypt(_salt, options.to);}
        if(options.manufacturer.length > 0 ){d_MFG = module.exports.decrypt(_salt, options.manufacturer);}
        if(options.lot.length > 0 ){d_LOT = module.exports.decrypt(_salt, options.lot);}
        if(options.expiry.length > 0 ){d_EXPIRATION = module.exports.decrypt(_salt, options.expiry);}
        if(options.distributed.length > 0 ){d_Distributed = module.exports.decrypt(_salt, options.distributed);}
        if(options.ndc.length > 0 ){d_NDC = module.exports.decrypt(_salt, options.ndc);}
        if(options.firstName.length > 0 ){d_FirstName = module.exports.decrypt(_salt, options.firstName);}
        if(options.mi.length > 0 ){d_MiddleName = module.exports.decrypt(_salt, options.mi);}
        if(options.lastName.length > 0 ){d_LastName = module.exports.decrypt(_salt, options.lastName);}
        if(options.birthday.length > 0 ){d_BIRTHDAY = module.exports.decrypt(_salt, options.birthday);}
        if(options.site.length > 0){d_Site = module.exports.decrypt(_salt, options.site);}
        if(options.lastAttempt.length > 0){d_LastAttempt = module.exports.decrypt(_salt, options.lastAttempt);}
        decrypted =  {to: d_Address, manufacturer: d_MFG, ndc: d_NDC, lot: d_LOT, expiry: d_EXPIRATION, distributed: d_Distributed, site: d_Site, firstName: d_FirstName, mi: d_MiddleName, lastName: d_LastName, birthday: d_BIRTHDAY, lastAttempt: d_LastAttempt}
        console.log("Returning Decrypted Object: ", decrypted);
        return decrypted;

    }
}

// / encrypting
// const encrypted_text = crypt("salt", "Hello"); // -> 426f666665

// // decrypting
// const decrypted_string = decrypt("salt", "426f666665"); // -> Hello