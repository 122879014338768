const Utilities = require("./Utilities.js");
const Run = require("run-sdk");
const bsv = require("bsv");
const Message = require("bsv/message");
module.exports = {
    signRecord(_run, mfg, ndc, lot, expiry, distributed){
        let signKey = bsv.PrivateKey.fromString(_run.owner.privkey);
        var message = mfg + ndc + lot + expiry + distributed;
        console.log("Message before signing 4 signature", message)
        var sig = Message.sign( message, signKey);
        return sig; 
    },
    signWithName(_run, mfg, ndc, lot, expiry, distributed, firstName, mi, lastName){
        let signKey = bsv.PrivateKey.fromString(_run.owner.privkey);
        var message = mfg + ndc + lot + expiry + distributed + firstName + mi+ lastName;
        var sig = Message.sign( message, signKey);
        return sig; 
    },
    setupVXRecordTransaction(_run, _cred, _class,  _recipient, _options){
        let options = _options;
        _run.activate();
        if(options === undefined){
            options = {manufacturer: "Pfizer", ndc: "11122223333", lot: "A45FT66LL", expiry: "12/31", distributed: Utilities.dateString(-30), site:"Quite Waters", firstName:"John", mi:"A", lastName:"Doe"}
        }
        let sig1 = module.exports.signRecord(_run, options.manufacturer, options.ndc, options.lot, options.expiry, options.distributed);
        let sigwName = module.exports.signWithName(_run, options.manufacturer, options.ndc, options.lot, options.expiry, options.distributed, options.firstName, options.mi, options.lastName);
        let tx = new Run.Transaction()
        console.log("Options before TX", _options)
        tx.update(() => new _class(_cred, _recipient, options.manufacturer, options.ndc, options.lot, options.expiry, options.distributed, options.site, sig1, sigwName))
        return tx;
    },
    addRecordToTransaction (_run, _tx, _cred, _class, _recipient, _options){
        let options = _options;
        _run.activate();
        if(options === undefined){
            options = {manufacturer: "Pfizer", ndc: "11122223333", lot: "A45FT66LL", expiry: "12/31", distributed: Utilities.dateString(0),site:"Quite Waters", firstName:"John", mi:"A", lastName:"Doe"}
        }
        let sig2 = module.exports.signRecord(_run, options.manufacturer, options.ndc, options.lot, options.expiry, options.distributed);
        let sigwName2 = module.exports.signWithName(_run, options.manufacturer, options.ndc, options.lot, options.expiry, options.distributed, options.firstName, options.mi, options.lastName);
        console.log("Options before TX2", _options)
        _tx.update(() => new _class(_cred, _recipient, options.manufacturer, options.ndc, options.lot, options.expiry, options.distributed, options.site, sig2, sigwName2))
        return _tx
    },
    async loadRecordClass(_run, _toLoad){
        _run.activate();
        let _vxRecordClass = await _run.load(_toLoad)
        await _vxRecordClass.sync();
        return _vxRecordClass;
    }
}