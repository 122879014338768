<template>
<div class='flex space-x-4 p-4'>
    <div class='flex-grow '>
        <input v-model="patientAddress" class='min-w-sm w-full h-full rounded shadow' />
    </div>
    <div class="flex space-x-10">
        <div class='flex-grow'> 
            <button @click="savePatientFunction(patientAddress)" type="button" class="p-1 w-full inline-flex items-center justify-center border border-transparent text-sm font-medium rounded-md shadow-sm text-indigo-600 lg:p-2 focus:outline-none ">
                {{"SAVE"}}
            </button>
        </div>
    </div>
</div>
</template>

<script>
import Language from "./../../../constants/language/english.js";
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            patientAddress: "",
        })
    
        return {
            Language,
            ...toRefs(state),
        }
    },
    mounted(){
        this.patientAddress = this.$store.state.patientAddress
    },
    props:["savePatientFunction"]
}
</script>

<style lang="scss" scoped>

</style>