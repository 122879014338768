<template>
<!-- TODO: UPDATE ALL PLAIN TEXT TO LANGUAGE FILE IMPORT --> 
  <div class="ring-2 ring-pink-500 rounded">
    <div class="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
      <div class="text-center">
        <h2 class="text-lg leading-6 font-semibold text-indigo-600 uppercase tracking-wider">
          My Account
        </h2>
        <p class="mt-2 text-3xl font-extrabold font-extrabold text-transparent bg-clip-text bg-gradient-to-b from-pink-400 to-red-500 sm:text-4xl lg:text-5xl">
          Secure Your Account
        </p>
        <p class="mt-3 max-w-4xl mx-auto text-md text-white sm:mt-5 sm:text-md">
        VXPASS is built on blockchain to protect your privacy. This means your account is entirely private. We dont have servers that create your account, it was created on the device you first used to log in and never sent to VXPASS.
        </p>
      </div>
    </div>
    <slot name="buttons"> </slot>
    <div class="mt-16 pb-12 lg:mt-20 lg:pb-20">
      <div class="relative z-0">
        <div class="absolute inset-0 h-5/6  lg:h-2/3" />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative lg:grid lg:grid-cols-7">
            <div class="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
              <div class="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg ring-1 ring-indigo-400">
                <div class="flex-1 flex flex-col ">
                  <div class="bg-white px-6 py-10">
                    <div>
                      <h3 class="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                        Secure Your Seed
                      </h3>
                      <!-- <div class="mt-4 flex items-center justify-center">
                        <span class="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            79
                          </span>
                        </span>
                        <span class="text-xl font-medium text-gray-500">
                          /month
                        </span>
                      </div> -->
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                    <!-- <ul class="space-y-4">

                      <li v-for="feature in hobbyFeatures" :key="feature" class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">
                          {{ feature }}
                        </p>
                      </li>
                    </ul> -->
                    <p>A simple way to protect your account is to send half of your seed to one place (email), and the other half to another place (text message). </p>
                    
                    <div class="mt-8">
                      <div class="rounded-lg shadow-md">
                        <!-- <a href="#" class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50" aria-describedby="tier-hobby">
                          Start your trial
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
              <div class="relative z-10 rounded-lg shadow-xl ring-1 ring-pink-400">
                <div class="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600" aria-hidden="true" />
                <div class="absolute inset-x-0 top-0 transform translate-y-px">
                  <div class="flex justify-center transform -translate-y-1/2">
                    <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                      Critical
                    </span>
                  </div>
                </div>
                <div class="bg-white rounded-t-lg px-6 pt-12 pb-10">
                  <div>
                    <h3 class="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">
                      Do This Now!
                    </h3>
                    
                    <div class="mt-4 flex items-center justify-center">
                      <span class="px-3 flex items-start text-4xl tracking-tight text-gray-900 sm:text-4xl">
                        <!-- <span class="mt-2 mr-2 text-4xl font-medium">
                          $
                        </span> -->
                        <span class="font-extrabold">
                        Split Your Seed
                        </span>
                      </span>
                      <!-- <span class="text-2xl font-medium text-gray-500">
                        /month
                      </span> -->
                    </div>
                  </div>
                </div>
                <div class="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                    
                  <ul class="space-y-4">
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <CheckIcon class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                      </div>
                      <p class="ml-3 text-base font-medium text-gray-500">
                        Email should have 2-factor authentication
                      </p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <CheckIcon class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                      </div>
                      <p class="ml-3 text-base font-medium text-gray-500">
                      SMS device should have bio security
                      </p>
                    </li>
                  </ul>
                  
                  <div class="mt-10">
                    <div class="rounded-lg shadow-md">
                      <a href="sms:19131234567;?&body=Question%20from%20mywebsite.com.%20%20MY%20MESSAGE%20-%20" title="Click here to TEXT US gallery token needs updating!">Send me SMS</a>

                      <a :href="emailHref" class="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-700" aria-describedby="tier-growth">
                        Send First 6 via Email
                      </a>
                    </div>
                    <div class=" pt-1 rounded-lg shadow-md">
                      <a :href="smsHref" class="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-700" aria-describedby="tier-growth">
                        Send Last 6 via SMS
                      </a>
                    </div>
                    <slot name="copyAll"></slot>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
              <div class="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg ring-1 ring-indigo-400">
                <div class="flex-1 flex flex-col">
                  <div class="bg-white px-6 py-10">
                    <div>
                      <h3 class="text-center font-semibold text-2xl font-medium text-red-500" id="tier-scale">
                        No Recovery
                      </h3>
                      <!-- <div class="mt-4 flex items-center justify-center">
                        <span class="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                          <span class="mt-2 mr-2 text-4xl font-medium">
                            $
                          </span>
                          <span class="font-extrabold">
                            349
                          </span>
                        </span>
                        <span class="text-xl font-medium text-gray-500">
                          /month
                        </span>
                      </div> -->
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                    <!-- <ul class="space-y-4">
                      <li v-for="feature in scaleFeatures" :key="feature" class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base font-medium text-gray-500">
                          {{ feature }}
                        </p>
                      </li>
                    </ul> -->
                    <p>For that reason we <span class='text-red-500'> CAN NOT </span> recover your seed or private key.</p>
                    <p>You can use your Seed as your secure password to VXPASS, but if you would like to take your records to another service you may also need you Private Key.</p>
                    <div class="mt-8">
                      <div class="rounded-lg shadow-md">
                        <!-- <a href="#" class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50" aria-describedby="tier-scale">
                          Start your trial
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'

const hobbyFeatures = ['Pariatur quod similique', 'Sapiente libero doloribus', 'Vel ipsa esse repudiandae']
const scaleFeatures = ['Pariatur quod similique', 'Sapiente libero doloribus', 'Vel ipsa esse repudiandae']
const growthFeatures = [
  'Quia rem est sed impedit magnam',
  'Dolorem vero ratione voluptates',
  'Qui sed ab doloribus voluptatem dolore',
  'Laborum commodi molestiae id et fugiat',
  'Nam ut ipsa nesciunt culpa modi dolor',
]

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      hobbyFeatures,
      scaleFeatures,
      growthFeatures,
    }
  },
  computed: {
    emailHref(){
      
      let _seed = this.$store.state.patientSeed.split(" ").slice(0, 6).join(' ')
      console.log(encodeURIComponent(_seed))
      return `mailto:?&subject='My VX Info'&body=${encodeURIComponent(_seed)}`
    },
    smsHref(){
      let _seed = this.$store.state.patientSeed.split(" ").slice(0, 6).join(' ')
      console.log(encodeURIComponent(_seed))
      return `sms:;?&body=${encodeURIComponent(_seed)}`
    },
  },
}
</script>